<template>
  <TopNav />
  <section class="form-section">
    <div class="row d-flex justify-nav">
      <Sidenav />
      <div class="col-9 p-0 form-heading shadow">
        <div class="progress-container">
            <ul class="progress-steps">
              <li class="step active">My Information</li>
              <li class="step active">Personal Info</li>
              <li class="step active">Document verifications</li>
              <li class="step active">Select Slot</li>
              <li class="step">Video KYC</li>
            </ul>

            <ul class="progress-indicators">
              <li class="indicator active"></li>
              <li class="indicator active"></li>
              <li class="indicator active"></li>
              <li class="indicator active"></li>
              <li class="indicator"></li>
            </ul>

            <div class="progress-bar-container">
              <div class="progress-bar"></div>
            </div>
          </div>
        <div class="row d-flex flex-row justify-content-between align-items-center">
          <div class="col-md-6">

            <div class="pb-4">
              <div class="card-title mb-0 me-1">
                <h5 class="mb-0 text-lorem">Select Slot</h5>
                <p class="mb-0 text-size">Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>
              </div>
              <div class="mb-3 mt-3 d-flex flex-row justify-content-between align-items-center">
                <div>
                  <label for="selectslot" class="form-label text-lorem pt-2 text-lorem pr-2">Select date for
                    vKYC</label>
                </div>
                <div>

                  <flat-pickr v-model="selectedDate" id="selectslot" :config="datePickerConfig" :min="minDate"
                    @input="updateScheduleDate"
                    style="border: 1px solid gray; padding: 6px; border-radius: 6px; font-size: 12px"
                    placeholder="Select date"></flat-pickr>

                </div>

                <!-- <input
type="date"
class="form-control"

v-model="selectedDate"
@input="updateScheduleDate"

:disabled="isWeekend(selectedDate)"
:readonly="isWeekend(selectedDate)"
/> -->

                <!-- 
<div class="date-picker-container">
<span class="date-icon">
<i class="fas fa-calendar"></i>
</span>
<flat-pickr v-model="selectedDate" :config="datePickerConfig"></flat-pickr>
</div> - -->


              </div>
              <div class="for-time">
                <!-- <div class="first px-3 py-2 d-flex flex-row justify-content-between">
   <h2 class="fw-bold patt vColour">Your Schedule For PD</h2>
    <span class="fw-bold pt-2 text-muted " style="
font-size: 18px!important;
">{{ scheduleDate }}</span>
  </div> -->

                <div class="time">
                  <div v-for="(schedule, index) in schedules" :key="index" @click="selectSchedule(index)">
                    <div :class="['box-control', { selected: schedule.selected }]">
                      <p class="time-text">{{ schedule.time }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex flex-row gap-5" style="justify-content: space-between;">
              <div class="col-sm-5">
                <div class="">
                  <div class="login-button">
                    <button type="button" class="btn btn-primary" @click="goBack()" style="
font-size: 13px;
"> Back</button>
                  </div>
                </div>

              </div>
              <div class="col-sm-5 ">

                <div class="">
                  <div class="login-button">
                    <button type="submit" class="btn btn-primary" :disabled="!scheduleSelected"
                      @click="navigateToVcipInDetails" style="
font-size: 13px;
">
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>



          </div>
          <div class="col-md-6 d-none">
            <div class="paddingTop rounded5 tips-container">
              <h5 class="tips-title">Tips for Booking a Loan Slot</h5>
              <ul class="tips-list">
                <li>Book early to ensure your preferred time slot is available.</li>
                <li>Double-check your documents before booking to avoid delays.</li>
                <li>Select a time when you’re least likely to be interrupted.</li>
                <li>Have a stable internet connection for a smooth vKYC process.</li>
                <li>Reach out to support if you face any issues during booking.</li>
                <li>Ensure your contact details are up-to-date to receive timely reminders.</li>
                <li>Choose a quiet environment to avoid distractions during the vKYC.</li>
                <li>Review the loan terms and conditions before confirming the slot.</li>

              </ul>
            </div>
          </div>


        </div>
      </div>
    </div>
  </section>

  <footer>
    <div class="navbar-nav-footer">
      <a class="nav-item nav-link active" href="#">
        <span class="material-symbols-outlined"> border_color </span>
        <span>Apply</span>
      </a>
      <a class="nav-item nav-link" href="#">
        <span class="material-symbols-outlined"> group </span>
        <span>Users</span>
      </a>
      <a class="nav-item nav-link" href="#">
        <span class="material-symbols-outlined"> article </span>
        <span>Docs</span>
      </a>
      <a class="nav-item nav-link" href="#">
        <span class="material-symbols-outlined"> insert_chart </span>
        <span>Reports</span>
      </a>
      <a class="nav-item nav-link" href="#">
        <span class="material-symbols-outlined"> local_police </span>
        <span>Policy</span>
      </a>
    </div>
  </footer>
</template>

<script>
import axios from "axios";
import FlatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import TopNav from '@/components/topNav.vue';
import Sidenav from '@/components/sidenav.vue';
export default {
  name: "selectSlot",
  components: {
    FlatPickr,
    TopNav,
    Sidenav,
  },
  data() {
    return {
      agree: false,
      // selectedDate: "",
      scheduleDate: "",
      // minDate: "today",
      defaultDate: 'today',
      schedules: [
        { time: "09:00 AM", selected: false },
        { time: "09:30 AM", selected: false },
        { time: "10:00 AM", selected: false },
        { time: "10:30 AM", selected: false },
        { time: "11:00 AM", selected: false },
        { time: "11:30 AM", selected: false },
        { time: "12:00 PM", selected: false },
        { time: "12:30 PM", selected: false },
        { time: "01:00 PM", selected: false },
        { time: "01:30 PM", selected: false },
        { time: "02:00 PM", selected: false },
        { time: "02:30 PM", selected: false },
        { time: "03:00 PM", selected: false },
        { time: "03:30 PM", selected: false },
        { time: "04:00 PM", selected: false },
        { time: "04:30 PM", selected: false },
        { time: "05:00 PM", selected: false },
      ],
      scheduleSelected: false,
      linkUrl: "",

      selectedDate: null,
      datePickerConfig: {
        minDate: 'today',
        disable: [
          function (date) {
            // Disable weekends
            return (date.getDay() === 0 || date.getDay() === 6);
          }
        ]
      },
      caseData2: {}

    };
  },
  methods: {
    navigateTooVcipInDetails(id) {
      this.$router.push("/vcipIn?id=" + id);
    },

    async fetchCaseById() {

      try {
        const id = this.$route.query.id;
        const scriptElement = document.querySelector("script[api-url]");
        const api = scriptElement.getAttribute("api-url");
        const apiUrl = `${api}/case/getById/${id}`;
        const response = await axios.post(apiUrl);

        this.caseData2 = response.data;
        console.log("Case data:", this.caseData2);
        this.flexDivDisplay = "flex!important";
        this.flexDivDisplay2 = "flex!important";
        this.flexDivDisplay3 = "flex!important";

      } catch (error) {
        console.error("Error fetching case data:", error);
      }
    },



    async navigateToVcipInDetails() {

      if (!this.selectedDate || !this.scheduleDate || !this.scheduleSelected) {
        return;
      }

      const id = this.$route.query.id;
      const scriptElement = document.querySelector("script[api-url]");
      const apiUrl = scriptElement.getAttribute("api-url");
      // this.linkUrl = `http://localhost:8080/vcipin?id=${id}`;
      this.linkUrl = `${apiUrl}/vcipin?id=${id}`;

      const updateData = {
        selectedDate: this.selectedDate,
        schedules: this.schedules,
        link: this.linkUrl,
        status: "Select Slot Success"
      };



      try {
        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");
        const response = await axios.put(
          `${apiUrl}/case/update/${id}`,
          updateData
        );

        console.log("Update response:", response);

        if (response.data.message === "Case successfully updated") {
          this.navigateToVcipInDetails2();
          this.navigateTooVcipInDetails(id);
        } else {
          console.error("Update failed:", response.data.message);
        }
      } catch (error) {
        console.error("API call error:", error);
      }
    },

    async navigateToVcipInDetails2() {
      const id = this.$route.query.id;

      const updateData = {
        id: id,
        subject: "23423423 Verification",
        caseData: {
          email: this.caseData2.email
        },
        html: `Dear User,<br/>Please click on the button below to proceed further for verification.<br/><a href="${this.linkUrl}" style="display: inline-block; background-color: #007BFF; color: #fff; text-decoration: none; padding: 10px 20px; border-radius: 5px;">Verify Now</a>`
      };

      try {
        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");
        const response = await axios.post(
          `${apiUrl}/case/sendVkycLink`,
          updateData
        );

        console.log("Update response:", response);

        if (response.data.message === "Case successfully updated") {
          this.navigateTooVcipInDetails(id);
        } else {
          console.error("Update failed:", response.data.message);
        }
      } catch (error) {
        console.error("API call error:", error);
      }
    },







    updateScheduleDate() {
      this.scheduleDate = this.selectedDate;
    },
    selectSchedule(index) {
      this.schedules.forEach((schedule, i) => {
        schedule.selected = i === index;
      });

      this.scheduleSelected = this.schedules.some(
        (schedule) => schedule.selected
      );
    },
    goBack() {
      // Go back using Vue Router
      this.$router.go(-1);
    }
    ,
    isWeekend(date) {
      const day = new Date(date).getDay();
      return day === 0 || day === 6; // Sunday (0) or Saturday (6)
    }
  },
  mounted() {



    document.title = 'Select Slot';
    const today = new Date();
    today.setDate(today.getDate() + 1);
    this.minDate = today.toISOString().substr(0, 10); // Set minDate to today
    this.selectedDate = this.minDate; // Set selectedDate to today
    this.scheduleDate = this.minDate; // Set scheduleDate to today
  },
};
</script>

<style scoped>
.form-label {
  margin-bottom: 0.2rem;
}

.selected-schedule {
  background-color: green;
  color: white;
}

.available-schedule {
  background-color: #cceeff;
}

.for-time {
  height: 238px;
  /* scroll-behavior: auto; */
  /* scroll-snap-type: none; */
}

.time {
  height: 70%;
}

.bosx-control {
  height: 48px;
  padding: 1rem;
  border: 1px solid #c3c3c32b;
  border-left: none;
  border-bottom: none;
  background-color: #f4f4f4d9;
}

.for-avl {
  cursor: pointer;
}

.bosx-control p {
  color: #b7b7b7;
}

.form-control {
  background-color: white;
  border: 1px solid #b7b7b7;
}

.process-chart-mobile {
  display: none;
}

.navbar {
  padding: 0.5rem 3rem;
}

.navbar-brand {
  font-size: xx-large;
  font-weight: 600;
}

.nav-item {
  align-items: center;
  font-size: small;
}

.form-section {
  position: relative;
  padding: 1rem;
  background-color: #f8f7fa;
  min-height: 100vh;
}

.heading-title-circle,
.heading-title {
  padding-right: 2rem;
}

.b-w-msg {
  text-align: start;
  font-size: x-large;
  font-weight: bold;
  margin-top: 3rem;
}

.row {
  justify-content: center;
}

.page-form {
  padding-block: 2rem;
  padding-inline: 4rem;
}

.form-heading {
  padding: 2rem !important;
  background-color: white;
  border-radius: 8px;
}

.progress {
  height: 3px;
  margin: -27px 2rem 20px 2rem;
  background-color: rgb(184, 183, 183);
}

.progress-bar {
  width: 50%;
  background-color: black;
}

.process-chart {
  padding: 15px;
}

.input-2 {
  display: flex;
}

.b-box {
  margin: 0px auto;
  width: 30%;
  margin-bottom: 20px;
}

.heading-title {
  color: black;
  list-style: none;
  display: flex;
  justify-content: space-between;
  font-size: x-large;
  font-weight: 600;
}

.form-check-label {
  color: black;
  /* Change the color to your preference */
}

.heading-title-circle {
  display: flex;
  justify-content: space-between;
  list-style: none;
}

.vcip {
  margin-left: 4rem;
}

.btn.btn-primary {
  width: 100%;
  background-color: #7367f0;
  border: 2px solid #7367f0;
  margin-top: 1.5rem;
}

.nav-item {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

form {
  padding: 2rem;
  padding-top: 0rem;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading-title-circle li {
  width: 17px;
  height: 17px;
  background-color: white;
  border: 2px solid grey;
  border-radius: 50%;
  z-index: 1;
}

.heading-title-circle li.active {
  border: 5px solid black;
  border-radius: 50%;
}

.v-t {
  margin-top: 10px;
  margin-bottom: 2px;
  text-align: start;
}

.form-check {
  display: flex;
  gap: 10px;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

.input-box .input-1 {
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  color: #202124;
  border: 1px solid rgb(0, 0, 0, 0.6);
  padding: 15px;
  transition: 250ms;
}

.btn.btn-primary {
  margin-bottom: 0.85rem;
}

#agreeCheckbox:checked {
  accent-color: #202124;
}

.checkbox-label {
  margin-bottom: 1rem;
}

footer {
  display: none;
}

@media (max-width: 768px) {
  .navbar {
    display: none;
  }

  .form-section {
    position: relative;
    padding: 0px 0px;
    background-color: #f8f7fa;
    min-height: auto;
  }

  .process-chart {
    display: none;
  }

  .form-container {
    min-height: 84vh;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  form {
    padding-inline: 0.5rem;
  }

  .b-w-msg {
    margin-top: 1rem;
  }

  .v-t {
    margin-top: 1rem;
  }

  .btn-submit-div {
    position: absolute;
    bottom: 1rem;
    left: 2rem;
    right: 2rem;
  }

  .heading-title-circle .li3 {
    margin-left: 2rem;
  }

  .form-check-label {
    font-size: 1.1rem;
  }

  .form-check {
    margin-block: 1rem;
  }

  .btn.btn-primary {
    margin-bottom: 0.5rem;
    margin-top: 1rem;
  }

  .vcip {
    margin-left: -6rem;
  }

  footer {
    display: block;
    height: 100%;
  }

  .submit-btn-checbox {
    margin-top: 15rem;
  }

  .navbar-nav-footer {
    display: flex;
    column-gap: 22px;
    justify-content: space-between;
    flex-direction: row;
    position: fixed;
    padding: 1rem;
    bottom: 0;
    width: 100%;
  }

  .navbar-nav-footer .nav-link {
    color: grey;
  }

  .navbar-nav-footer .nav-link :nth-child(2) {
    font-size: small;
    font-weight: bold;
  }

  .navbar-nav-footer .nav-link.active {
    color: black;
  }

  .material-symbols-outlined {
    font-size: 34px;
  }
}

.rounded5 {
  padding: 20px;
  border-radius: 20px;
  width: 100%;
}

.fifty32 {
  width: 55%;
}

.time[data-v-2e3833ac] {
  height: 77%;
}

.fw-bold {
  font-size: x-large !important;
  font-weight: bold !important;
  text-align: start !important;
  margin-bottom: 0px !important;
}

.patt {
  padding-top: 10px !important;
}


.vColour {
  color: #198754cf;
  font-size: 22px !important;
}

.time[data-v-2e3833ac][data-v-2e3833ac] {
  height: 100%;
}

.justify-nav {
  justify-content: space-evenly;
}

.text-lorem {
  font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 500 !important;
  font-style: normal !important;
  font-size: 18px !important;
  line-height: 36px !important;
  color: rgb(93, 89, 108) !important;
}

.text-size {
  font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 400 !important;
  font-style: normal !important;
  font-size: 15px !important;
  line-height: 22px !important;
  color: #a5a3ae !important;
}

.tips-container {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #e3e6f0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.tips-title {
  font-size: 18px;
  font-weight: 600;
  color: #343a40;
  margin-bottom: 10px;
}

.tips-list {
  list-style-type: disc;
  padding-left: 20px;
  color: #495057;
  font-size: 14px;
  line-height: 1.6;
}

.tips-list li {
  margin-bottom: 8px;
}

.shadow {
  background-clip: padding-box;
  box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
  padding: 1rem 2rem 2rem 2rem;
}

.time {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  padding: 0;
}

.box-control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .5rem;
  justify-content: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  transition: background-color 0.3s;
}

.box-control.selected {
  background-color: #e8eaf6;
  border-color: #7367f0;
  color: #7367f0;
}

.box-control p {
  margin: 0;
}

.box-control .time-text {
  font-weight: bold;
  color: #333;
  font-size: 12px;
}

.box-control.selected .time-text {
  color: #7367f0;
  font-weight: bold;
}
.paddingTop {
  border-left: 4px solid #7367f0;
  background-color: #f9f9ff !important;
}
/* Container Styles */
.progress-container {
  width: 100%;
  margin: 0;
  text-align: center;
  padding: 20px;
}

/* Step Titles */
.progress-steps {
  list-style: none;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0;
}

.progress-steps .step {
  font-size: 14px;
  font-weight: 500;
  color: #9e9e9e;
  position: relative;
}

.progress-steps .active {
  color: #7367f0; /* Highlight active step */
  font-weight: 600;
}

/* Step Indicators */
.progress-indicators {
  list-style: none;
  display: flex;
  justify-content: space-between;
  margin: 0 10px;
  padding: 0;
}

.progress-indicators .indicator {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #e0e0e0;
}

.progress-indicators .active {
  background-color: #7367f0; /* Green for active step */
}

/* Progress Bar */
.progress-bar-container {
  width: 100%;
  height: 8px;
  background-color: #e0e0e0;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

.progress-bar {
  width: 75%; /* Adjust width dynamically based on the current step */
  height: 100%;
  background-color: #7367f0;
  transition: width 0.3s ease-in-out;
}
</style>

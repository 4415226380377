<template>
  <div>
    <TopNav />
    <section class="form-section">
      <div class="justify-nav">
        <div class="col-md-3 d-flex flex-column flex-shrink-0 p-3 text-white bg-white bg-color" style="width: 280px; border-radius: 10px;">
          <DashboardSideNav @navigate="handleNavigation"/>
        </div>
        <div class="col-md-9 p-0 index">
          <div class="open-screen">
            <div  v-if="currentView === 'dashboard'">
              <dashboard-table></dashboard-table>
            </div>
            <div v-if="currentView === 'live'"><live-cases-screen></live-cases-screen></div>
            <div v-if="currentView === 'analytics'"><analytics-screen></analytics-screen></div>
            <div v-if="currentView === 'setting'">
              <My-component></My-component>
            </div>
          </div>
        </div>
      </div>

    </section>
  </div>
</template>

<script>
import DashboardTable from "./dashboardTable.vue";
import LiveCasesScreen from "./liveCasesScreen.vue";
import thresholdDetails from "./thresholdDetails.vue";
import AnalyticsScreen from './analyticsScreen.vue';
import TopNav from '@/components/topNav.vue';
import DashboardSideNav from '@/components/dashboardSideNav.vue';

export default {
  components: {
    "My-component": thresholdDetails,
    DashboardTable,
    LiveCasesScreen,
    AnalyticsScreen,
    TopNav,
    DashboardSideNav,
  },
  name: "dashboardScreen",
  data() {
    return {
      dashboards: true,
      lives: false,
      analyticss: false,
      settings: false,
      popUp: false,
      currentView: 'dashboard',
    };
  },
  methods: {
    handleNavigation(view) {
      this.currentView = view;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.form-section {
  position: relative;
  /* padding: 25px 20px; */
  background-color: rgb(255, 255, 255);
  /* min-height: 100vh; */
}

.login-page {
  background-color: #fff;
  overflow-y: auto;
  scrollbar-width: none;
  position: fixed;
  z-index: 90;
  height: calc(100vh - 80px);
  top: 80px;
  min-height: calc(100vh - 80px);
  box-shadow: 0 10px 20px 1px rgba(0, 0, 0, 0.06),
    0 1px 5px rgba(11, 11, 11, 0.08);
}

a {
  text-decoration: none;
  color: #05070b;
}

.btn.btn-primary {
  width: 100%;
  background-color: #0053f9;
  border: 2px solid #0053f9;
  margin-top: 1.5rem;
}


.av-test-btn {
  padding: 5px 20px;
  border-radius: 20px;
  background-color: #ee8d33;
  border: 2px solid #eb780d;
  color: white;
}

@media (max-width: 768px) {
  .form-section {
    width: 100%;
    padding: 0px 0px;
  }

  .login-page {
    border: none;
    border-radius: 0px;
    min-height: 100vh;
    padding: 1rem;
  }

  .btn.btn-primary {
    width: 100%;
    /* */
    margin: 1.5rem 0;
  }

  .camera {
    height: 30vh;
    border-radius: 10px;
    margin-bottom: 2rem;
  }

  .login-header {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: larger;
  }
}

.mr-li {
  margin: 0.125rem 0;
  padding: 1rem !important;
  display: flex;
  align-items: center;
}
.justify-nav {
  display: flex;
  padding: 0rem;
  width: 100%;
}
/* . {
  color: #7367f0;
} */
</style>

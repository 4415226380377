<template>
  <section class="form-section">
    <div class="w-100 p-0 form-heading">
      <div class="container">
        <div class="card-title mb-0 me-1">
          <h5 class="mb-0 text-lorem">Live Cases</h5>
          <p class="mb-0 text-size">Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>
        </div>
        <div class="d-flex flex-row gap-5 justify-content-space-between align-items-center">
          <div class="mt-4">
            <canvas ref="pieChart" width="280" height="280"></canvas>
          </div>
        </div>
        <div class="stats-container mt-2">
          <div class="stat-item2">
            <h2>{{ toDayCount }}</h2>
            <p class="text-size" style="color: #fff !important; margin-top: 0;">Active Cases</p>
          </div>
          <div class="stat-item1">
            <h2>{{ totalCount }}</h2>
            <p class="text-size" style="color: #fff !important; margin-top: 0;">Reported Cases</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Chart from "chart.js/auto";

export default {
  name: "LiveCasesScreen",

  created() {
    this.getAllLiveCases();
  },

  data() {
    return {
      pageTitle: 'Live Cases',
      totalCount: null,
      toDayCount: null,
      loader: false,
    };
  },

  mounted() {
    document.title = this.pageTitle;
  },

  methods: {
    async getAllLiveCases() {
      this.loader = true;
      try {
        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");
        const response = await axios.get(`${apiUrl}/case/getCaseCounts`);
        this.totalCount = response.data.totalCount;
        this.toDayCount = response.data.todayCount;

        this.drawPieChart();

        this.loader = false;
      } catch (error) {
        this.loader = false;
        console.error("Error during API call:", error);
      }
    },

    drawPieChart() {
      const pieChartData = {
        labels: ["Total Cases", "Live Cases"],
        datasets: [
          {
            backgroundColor: ["#36A2EB", "#FF6384"],
            data: [this.totalCount, this.toDayCount],
          },
        ],
      };

      if (this.$refs.pieChart) {
        const ctx = this.$refs.pieChart.getContext("2d");
        new Chart(ctx, {
          type: "pie",
          data: pieChartData,
          options: {
            responsive: true,
            maintainAspectRatio: false,
          },
        });
      }
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.form-section {
  background-color: #073991;
  min-height: calc(100vh-80px);
  max-height: calc(100vh-80px);
}

.login-page {
  background-color: #fff;
  border: 1px solid rgb(192, 192, 192);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background overlay */
  z-index: 999;
  /* Ensure the overlay is on top */
}

.loader {
  border: 1px solid #f3f3f3;
  border-top: 1px solid #3498db;
  border-radius: 100px;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.btn-primary {
  /* width: 100%; */
  height: 32px;
  padding: 2px 8px;
  background-color: #ee8d33;
  border: 2px solid #eb780d;
  /* margin-top: 1.5rem; */
}

@media (max-width: 768px) {
  .form-section {
    width: 100%;
    padding: 0px 0px;
  }

  .login-page {
    border: none;
    border-radius: 0px;
    min-height: 100vh;
    padding: 1rem;
  }

  .btn.btn-primary {
    width: 100%;
    /* */
    margin: 1.5rem 0;
  }
}

.shadow {
  background-clip: padding-box;
  box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
  padding: 1rem 2rem 2rem 2rem;
}

.form-heading {
  background-color: #fff;
  border-radius: 10px;
  padding: 1rem !important;
}
.text-size {
  font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 400 !important;
  font-style: normal !important;
  font-size: 16px !important;
  line-height: 22px !important;
  color: #a5a3ae !important;
  margin-top: 1rem;
}

.text-lorem {
  font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 500 !important;
  font-style: normal !important;
  font-size: 18px !important;
  line-height: 36px !important;
  color: rgb(93, 89, 108) !important;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Arial', sans-serif;
}

body {
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.stats-container {
  display: flex;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  padding: 1rem;
  text-align: center;
  color: #fff;
}

.stat-item1 {
  border-radius: 6px;
  margin-left: 8px;
  width: 33%;
  background-color: #34a3eb;
  padding: 1rem;
}

.stat-item2 {
  border-radius: 6px;
  width: 33%;
  background-color: #fb456c;
  padding: 1rem;
}

.stat-item h1 {
  font-size: 3rem;
  margin-bottom: 10px;
  color: #000;
}

.stat-item p {
  font-size: 1rem;
  color: #6c757d;
}

</style>

<template>
  <section class="form-section">
    <div class="w-100 p-0 form-heading">
      <div class="container">
        <div class="card-title mb-0 me-1">
          <h5 class="mb-0 text-lorem">Settings</h5>
          <p class="mb-0 text-size">Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>
        </div>
        <div class="login-page">
          <div class="toster" v-if="popUp">
            <div class="d-flex justify-content-between p-2 px-3 first">
              <div>Success</div>
              <div @click="closePopUp" class="pop-btn">x</div>
            </div>

            <div class="px-3 second">data update successfully</div>
          </div>

          <div class="">
            <div class="mt-4 mb-4">
              <ul class="nav nav-pills mb-3 nav-fill tab-list no_mobile" role="tablist">
                <li class="nav-item">
                  <button
                    type="button"
                    class="nav-link"
                    :class="{ active: openSec === 1 }"
                    role="tab"
                    @click="openSec = 1">
                    <i class="tf-icons ti ti-settings ti-xs me-1"></i> 
                    Settings
                  </button>
                </li>
                <li class="nav-item">
                  <button
                    type="button"
                    class="nav-link"
                    :class="{ active: openSec === 2 }"
                    role="tab"
                    @click="openSec = 2">
                    <i class="fa fa-question" aria-hidden="true"></i>
                    Questions
                  </button>
                </li>
              </ul>
            </div>

          </div>

          <div v-if="editModalVisible" class="modal">
            <div class="modal-content">
              <div>
                <span class="close" @click="closeModal" style="position: absolute; top: 10px; right: 15px; cursor: pointer; font-size: 24px;">&times;</span>
                <h5 v-if="isAdding" class="modal-title" id="exampleModalLabel2">Add Item</h5>
                <h5 class="modal-title" id="exampleModalLabel2" v-else>Edit Item</h5>
              </div>

              <div class="row mt-4">
                <div class="col-12" style="padding: 0;">
                  <div class="form-group">
                    <input
                      type="text"
                      placeholder="Question"
                      class="form-control"
                      v-model="editedItem.question"
                      :class="{ 'is-invalid': errors.question }"
                    />
                    <div class="invalid-feedback" v-if="errors.question">
                      Question is required.
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-12" style="padding: 0;">
                  <div class="form-group">
                    <textarea
                      class="form-control textarea"
                      placeholder="Write your description here"
                      v-model="editedItem.description"
                    ></textarea>
                  </div>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-12" style="text-align: right; padding: 0;">
                  <button @click="saveItem" class="align-self-end login-form-button rounded border-0 px-3 py-2">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div v-if="openSec == 1">
            <header class="">
                <div class="login-box">
                  <div class="row">

                    <p class="mb-2 p-0 text-size">Quality Settings</p>
                    <div
                      v-for="(option, index) in qualityOptions"
                      :key="'quality-' + index"
                      class="vcip-options col-4 mb-4 text-center"
                      style="display: flex; align-items: center;"
                    >
                      <div style="width:80%; padding: .5rem;">
                        <div class="option-label">{{ option.label }}</div>
                        <input
                          type="range"
                          min="0"
                          max="100"
                          v-model="option.value"
                          @change="updateThreshold(option.key)"
                          class="slider"
                        />
                      </div>
                      <div style="width:20%">
                        <div class="value-display">{{ option.value }}</div>
                      </div>
                    </div>

                    <p class="mb-2 p-0 text-size">Face Settings</p>
                    <div
                      v-for="(option, index) in faceOptions"
                      :key="'face-' + index"
                      class="vcip-options col-4 mb-4 text-center"
                      style="display: flex; align-items: center;"
                    >
                      <div style="width:80%; padding: .5rem;">
                        <div class="option-label">{{ option.label }}</div>
                        <input
                          type="range"
                          min="0"
                          max="100"
                          v-model="option.value"
                          @change="updateThreshold(option.key)"
                          class="slider"
                        />
                      </div>
                      <div style="width:20%">
                        <div class="value-display">{{ option.value }}</div>
                      </div>
                    </div>

                    <p class="mb-2 p-0 text-size">Environmental Thresholds</p>
                    <div
                      v-for="(option, index) in environmentOptions"
                      :key="'environment-' + index"
                      class="vcip-options col-4 mb-4 text-center"
                      style="display: flex; align-items: center;"
                    >
                      <div style="width:80%; padding: .5rem;">
                        <div class="option-label">{{ option.label }}</div>
                        <input
                          type="range"
                          min="0"
                          max="100"
                          v-model="option.value"
                          @change="updateThreshold(option.key)"
                          class="slider"
                        />
                      </div>
                      <div style="width:20%">
                        <div class="value-display">{{ option.value }}</div>
                      </div>
                    </div>

                    <p class="mb-2 p-0 text-size">Document Verification and Validation</p>
                    <div
                      v-for="(option, index) in documentOptions"
                      :key="'document-' + index"
                      class="vcip-options col-4 mb-4 text-center"
                      style="display: flex; align-items: center;"
                    >
                      <div style="width:80%; padding: .5rem;">
                        <div class="option-label">{{ option.label }}</div>
                        <input
                          type="range"
                          min="0"
                          max="100"
                          v-model="option.value"
                          @change="updateThreshold(option.key)"
                          class="slider"
                        />
                      </div>
                      <div style="width:20%">
                        <div class="value-display">{{ option.value }}</div>
                      </div>

                    </div>

                    <p class="mb-2 p-0 text-size">Miscellaneous</p>
                    <div
                      v-for="(option, index) in miscellaneousOptions"
                      :key="'miscellaneous-' + index"
                      class="vcip-options col-4 mb-4 text-center"
                      style="display: flex; align-items: center;"
                    >
                      <div style="width:80%; padding: .5rem;">
                        <div class="option-label">{{ option.label }}</div>
                        <input
                          type="range"
                          min="0"
                          max="100"
                          v-model="option.value"
                          @change="updateThreshold(option.key)"
                          class="slider"
                        />
                      </div>
                      <div style="width:20%">
                        <div class="value-display">{{ option.value }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </header>

            <div class="login-button align-self-start pl23" style="float: left;">
              <button
                class="login-form-button btn"
                :disabled="agree"
                @click="submitSettings"
              >
                Update
              </button>
            </div>
          </div>

          <div v-if="openSec == 2">
            <header class="">
              <div class="d-flex align-items-center justify-content-between mb-2" style="width: 66%;">
                <h2 class="mb-0 text-lorem">Frequently asked questions (FAQ)</h2>
<!--                 
                <i 
                  class="fas fa-plus" 
                  style="color: black; cursor: pointer; font-size: 24px; border: 2px solid black; border-radius: 50%; padding: 4px;" 
                  >
                </i> -->

              </div>
              <p @click="openAddModal()" style="text-decoration: underline; cursor: pointer;"> Create New Question</p>
              <ul class="list-group d-flex flex-column gap-1">
            <li
              v-for="(item, index) in items"
              :key="index"
              class="list-group-item cursor-pointer flex-row justify-content-between textAcco"
            >
              <div class="display-margin" @click="toggle(index)">
                <i :class="item.show ? 'fa fa-minus' : 'fa fa-plus'"
                  aria-hidden="true"
                  style="margin-right: 8px; color: #7367f0; font-weight: bold;">
                </i>
                <strong class="text-bolder" style="color: #6f6b7d">{{ item.question }}</strong>
              </div>
              <div v-if="item.show">
                <p class="padding-row mt-2">{{ item.description }}</p>
                <div class="d-flex flex-row gap-3" style="float: right;">
                <div>
                  <a class="text-white fw-bold " @click="editItem(index)">
                    <i class="fas fa-edit" style="cursor: pointer;"></i>
                  </a>
                </div>
                <div>
                  <a class="text-white fw-bold " @click="deleteItem(index)">
                    <i class="fas fa-trash" style="cursor: pointer;"></i>
                  </a>
                </div>
              </div>
              </div>
            </li>
          </ul>
            </header>
          </div>
          <div class="loader-container" v-if="loader">
            <div class="loader"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Swal from 'sweetalert2';
export default {
  name: "thresholdDetails",
  data() {
    return {
      isAdding: true,
      pageTitle: 'Settings',
      options: [],
      openSec: 2,
      popUp: false,
      backgroundThreshold: 0,
      aadhaarQuality: 0,
      lightThreshold: 0,
      items: [],
      newItem: {
        title: '',
        description: '',
        show: false,
      },
      voiceThreshold: 0,
      latLongThreshold: 0,
      uploadedPanQuality: 0,
      customerLiveliness: 0,
      livePhotographQuality: 0,
      liveCapturedPanQuality: 0,
      faceMatch: 0,
      showAadhar: false,
      showSecure: false,
      showDolor: false,
      qualityOptions: [],
      faceOptions: [],
      environmentOptions: [],
      documentOptions: [],
      miscellaneousOptions: [],
      showPrivacy: false,
      showAmet: false,
      showIpsum: false,
      randomQuestionnaire: 0,
      ovdsOcrCorrectness: 0,
      ovdsMasking: 0,
      xmlValidityCheck: 0,
      panVerification: 0,
      agree: false,
      loader: false,
      data: [
        // { id: 1, question: "First body", description: "First description" },
        // { id: 2, question: "Second body", description: "Second description" },
        // { id: 3, question: "Third body", description: "Third description" },
      ],
      editModalVisible: false,
      editedItem: {
        question: "",
        description: "",
      },
      errors: {
        question: false
      },
      editedItemIndex: null,
      addData: false,
      editData: false,
      myDataArray:[],
      currentIndex: null,
    };
  },

  mounted() {
    document.title = this.pageTitle;
    this.loadItems();
  },

  created() {
    // const jsonData = JSON.stringify(data);

    const jsonData = localStorage.getItem("randomQue");

    // Check if data exists in localStorage
    if (jsonData) {
      // Parse JSON data into JavaScript object
      const parsedData = JSON.parse(jsonData);

      // Store parsed data into Vue data array
      this.data = parsedData;
    }
    this.getData();
  },

  methods: {
    toggle(index) {
      this.items[index].show = !this.items[index].show;
    },
    openAddModal() {
      this.isAdding = true;
      this.editedItem = { question: '', description: '', show: false }; // Reset fields
      this.editModalVisible = true;
    },
    editItem(index) {
      this.isAdding = false;
      this.currentIndex = index;
      this.editedItem = { ...this.items[index] }; // Load item data into modal
      this.editModalVisible = true;
    },
    closeModal() {
      this.editModalVisible = false;
      this.currentIndex = null;
    },
    saveItem() {
      if (!this.editedItem.question) {
        this.errors.question = true;
        return;
      }

      this.errors.question = false;
      if (this.isAdding) {
        this.items.push({ ...this.editedItem });
      } else if (this.currentIndex !== null) {
        this.items.splice(this.currentIndex, 1, { ...this.editedItem });
      }
      this.saveToLocalStorage();
      this.closeModal();
    },
    loadItems() {
      const storedItems = localStorage.getItem('items');
      if (storedItems) {
        this.items = JSON.parse(storedItems).map(item => ({
          ...item,
          show: false, // Reset visibility to hidden on load
        }));
      } else {
        this.items = [];
      }
    },
    saveToLocalStorage() {
      localStorage.setItem('items', JSON.stringify(this.items));
    },
    deleteItem(index) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.items.splice(index, 1); 
          this.saveToLocalStorage();

          Swal.fire(
            'Deleted!',
            'Your item has been deleted.',
            'success'
          );
        }
      });
    },
    closePopUp() {
      this.popUp = false;
    },
    updateThreshold(key) {
      const option = this.options1.find(opt => opt.key === key);
      if (!option) {
        console.error(`Option with key '${key}' not found`);
        return;
      }
      console.log(`Updated ${key} to ${option.value}`);
    },

    async submitSettings() {
      const settingUpDto = {};

      this.options1.forEach(option => {
        settingUpDto[option.key] = option.value;
      });

      try {
        this.loader = true;
        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");
        const response = await axios.post(`${apiUrl}/setting/createSetting`, settingUpDto);
        
        console.log(response.data.message);
        this.loader = false;

        // Show success popup for 2 seconds
        this.popUp = true;
        setTimeout(() => {
          this.popUp = false;
        }, 2000);
      } catch (error) {
        console.error("Error during API call:", error);
        this.loader = false;
      }
    },


    async getData() {
      try {
        this.loader = true;
        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");
        const response = await axios.get(`${apiUrl}/setting/getSetting`);
        const data = response.data.settings;
        console.log("API Response:", data);

        if (data && data.length > 0) {
          const setting = data[0];

          this.qualityOptions = [
            { label: 'Pan Quality', value: setting.uploadedPanQuality, key: 'uploadedPanQuality' },
            { label: 'Live Photograph Quality', value: setting.livePhotographQuality, key: 'livePhotographQuality' },
            { label: 'Live Captured Pan Quality', value: setting.liveCapturedPanQuality, key: 'liveCapturedPanQuality' },
            { label: 'Aadhaar Quality Check', value: setting.aadhaarQuality, key: 'aadhaarQuality' },
          ];

          this.faceOptions = [
            { label: 'Face Match', value: setting.faceMatch, key: 'faceMatch' },
            { label: 'Customer Liveliness', value: setting.customerLiveliness, key: 'customerLiveliness' },
          ];

          this.environmentOptions = [
            { label: 'Background', value: setting.backgroundThreshold, key: 'backgroundThreshold' },
            { label: 'Light', value: setting.lightThreshold, key: 'lightThreshold' },
            { label: 'Voice', value: setting.voiceThreshold, key: 'voiceThreshold' },
            { label: 'Lat Long', value: setting.latLongThreshold, key: 'latLongThreshold' },
          ];

          this.documentOptions = [
            { label: 'Ovds OCR Correctness', value: setting.ovdsOcrCorrectness, key: 'ovdsOcrCorrectness' },
            { label: 'Ovds Masking', value: setting.ovdsMasking, key: 'ovdsMasking' },
            { label: 'XML Validity Check', value: setting.xmlValidityCheck, key: 'xmlValidityCheck' },
            { label: 'Pan Verification', value: setting.panVerification, key: 'panVerification' },
          ];

          this.miscellaneousOptions = [
            { label: 'Random Questionnaire', value: setting.randomQuestionnaire, key: 'randomQuestionnaire' },
          ];

          this.options1 = [
            ...this.qualityOptions,
            ...this.faceOptions,
            ...this.environmentOptions,
            ...this.documentOptions,
            ...this.miscellaneousOptions,
          ];

          this.options2 = [...this.options1];
        } else {
          console.error("No settings found in the response.");
        }
        this.loader = false;
      } catch (error) {
        console.error("API Error:", error);
      }
    }

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.modal {
  display: block;
  position: fixed;
  z-index: 2000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}
.modal-content {
  position: relative;
  z-index: 3000;

  background-color: #fefefe;
  margin: 10% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 35%;
}
.pop-btn {
  cursor: pointer;
}
.toster {
  right: 10px;
  top: 10px;
  position: fixed;
  z-index: 9999999999;
  height: 80px;
  width: 350px;
  background-color: #7367f0;
  border-radius: 10px;
  float: right;
}
.first {
  background-color: #7367f0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: #fff;
  font-weight: bold;
}
.second {
  color: #fff;
  margin-top: 6px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
}
a {
  color: #42b983;
}
.right-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
  margin-right: 10px;
}
.form-section {
  position: relative;
  padding: 20px 30px;
  background-color: #073991;
  min-height: calc(100vh-80px);
}
.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 18;
  font-size: 20px;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background overlay */
  z-index: 999; /* Ensure the overlay is on top */
}

.loader {
  border: 1px solid #f3f3f3;
  border-top: 1px solid #3498db;
  border-radius: 100px;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

.recheck {
  color: #f7b519;
}
.good {
  color: #009688;
}
.bg-recheck {
  background-color: #f7b519;
}
.bg-good {
  background-color: #009688;
}

.pointer:hover {
  cursor: pointer;
}
.login-page {
  background-color: #fff;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
header.sticky-header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: #f4f4f4;
  height: 18vh;
}

main {
  overflow-y: auto;
}
.for-question {
  font-size: x-large;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  /* margin-left: 42px; */
}
.vcip-heading {
  font-size: x-large;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  margin-left: 42px;
}
.acceptane-options-line {
  display: flex;
  justify-content: space-between;
  margin: 0px 10px;
  margin-bottom: 10px;
}
.acceptance-options {
  color: gray;
  font-weight: bold;
  justify-content: space-between;
  margin: 0px 10px;
  margin-left: 14px;
  margin-top: -20px;
  padding-top: 10px;
  background-image: linear-gradient(to right, #6a68db 50%, gray 50%);
  background-size: 100% 2px;
  background-repeat: no-repeat;
}
.acceptance-items {
  display: flex;
  align-items: center;
}
.circle {
  width: 10px;
  height: 10px;
  background-color: grey;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.active-circle {
  background-color: #6a68db;
}
.active-check {
  width: 18px;
  height: 18px;
  color: white;
}
.active-btn {
  color: #6a68db;
}
.inner-circle {
  width: 4px;
  height: 4px;
  background-color: white;
  border-radius: 50%;
}

#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #fcfcfc;
  color: black;
}

.vcip-options {
  display: flex;
  justify-content: space-between;
  background-color: #fcfcfc;
  padding: 11px;
  border-radius: 4px;
  margin-right: 1rem;
  background: #a8c0ff !important;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #3f2b96, #a8c0ff) !important;  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #3f2b96, #a8c0ff) !important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.vcip-options-items {
  display: flex;
  align-items: center;
}
.login-form-button {
  background-color: #7367f0 !important;
  color: white;
  text-align: center;
  font-size: large;
}

@media (max-width: 768px) {
  .form-section {
    width: 100%;
    padding: 0px 0px;
  }
  .login-page {
    border: none;
    border-radius: 0px;
    min-height: 100vh;
  }
  vcip-options {
    padding: 15px 20px;
  }
  .vcip-options-items {
    font-size: small;
  }

  .vcip-options[data-v-053b37e7] {
    margin: 7px 0px;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    background-color: #fcfcfc;
    padding: 7px;
    border-radius: 4px;
    /* border-bottom: 2px solid #d4d4d4; */
    /* box-shadow: 0 0 4px rgba(0, 0, 0, 0.2); */
  }
  .pl23 {
    padding-left: 42px !important;
    padding-top: 8px !important;
  }
}
.text-size {
  font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 400 !important;
  font-style: normal !important;
  font-size: 16px !important;
  line-height: 22px !important;
  color: #a5a3ae !important;
}

.text-lorem {
  font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 500 !important;
  font-style: normal !important;
  font-size: 18px !important;
  line-height: 36px !important;
  color: rgb(93, 89, 108) !important;
}
.form-heading {
  background-color: #fff;
  border-radius: 10px;
}
.setting-btn, .question-btn {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 12px;
  text-align: center;
  font-weight: 500;
  background-color: #f8f9fa;
  transition: background-color 0.3s, box-shadow 0.3s;
  cursor: pointer;
}

.setting-btn:hover, .question-btn:hover {
  background-color: #e2e6ea;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
.small-outline-button {
  background-color: transparent;
  color: #28a745;
  border: 2px solid #28a745;
  border-radius: 20px;
  padding: 0px 12px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

.small-outline-button:hover {
  background-color: #28a745;
  color: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transform: scale(1.05);
}

.small-outline-button:active {
  transform: scale(0.98);
  box-shadow: none;
}

header {
  max-width: 900px;
  margin: 0 auto;
  background-color: #ffffff;
}

h2 {
  font-weight: 600;
}

.table thead {
  background-color: #e3f2fd;
}

.btn i {
  font-size: 1rem;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f8f9fa;
}

.table-hover tbody tr:hover {
  background-color: #e9ecef;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
}

.text-que {
  color: #7367f0 !important;
}

.btn-outline-primary {
    --bs-btn-color: #7367f0 !important;
    --bs-btn-border-color: #7367f0 !important;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #7367f0 !important;
    --bs-btn-hover-border-color: #v;
    --bs-btn-focus-shadow-rgb: 13, 110, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #7367f0 !important;
    --bs-btn-active-border-color: #7367f0 !important;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #7367f0 !important;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #7367f0 !important;
    --bs-gradient: none;

}

.fw-bold {
  color: rgb(34, 35, 37) !important;
}
.fa-link {
  color: rgb(34, 35, 37) !important;
}
th {
  color: rgb(34, 35, 37);
  font-size: 16px;
  font-weight: 500;
  font-family: Macan, "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.text-items {
  color: #6f6b7d;
  text-align: left;
  font-size: .90rem;
  font-family: Public Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}
.text-size {
  font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 400 !important;
  font-style: normal !important;
  font-size: 15px !important;
  line-height: 22px !important;
  color: #a5a3ae !important;
}
.pagination-div {
  display: flex;
  justify-content: flex-end;
  margin-right: 30px;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

.form-section {
  position: relative;
  /* padding: 25px 20px; */
  background-color: rgb(255, 255, 255);
  /* min-height: 100vh; */
}

.forcross {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: lightgrey;
  font-weight: bold;
  box-shadow: rgba(0, 0, 0, 0.3);
  color: white;
  cursor: pointer;
}

.login-page {
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.popup-Container {
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: absolute; */
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 110;
}

.pop_up {
  padding: 10px;
  position: absolute;
  width: 50%;
  height: 40%;
  background-color: white;
  z-index: 110;
}

.card-div {
  width: 100px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  background-color: aliceblue;
  border: 1px solid lightgray;
}

.loader {
  border: 1px solid #f3f3f3;
  border-top: 1px solid #3498db;
  border-radius: 100px;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.btn-primary {
  background-color: #ee8d33;
  border: 2px solid #eb780d;
  border-radius: 5px;
  color: #f3f3f3;
}

@media (max-width: 768px) {
  .form-section {
    width: 100%;
    padding: 0px 0px;
  }

  .login-page {
    border: none;
    border-radius: 0px;
    min-height: 100vh;
    padding: 1rem;
  }
}

.InputX {
  height: 30px;
  padding: 4px;
  border-radius: 7px;
  border: 1px solid #d9d1d1;
  color: #3c3c3c;
  /* font-size: 14px; */
  font-size: 12px;
  padding-left: 10px;
  /* font-size: 14px; */
}

.page-link.active,
.active>.page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: rgb(34, 35, 37);
  border-color: rgb(34, 35, 37);
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: rgb(34, 35, 37);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}


.fa-phone:before {
  content: "\f095";
  color: #00000066;
}

.justify-nav {
  justify-content: space-evenly;
}

.shadow {
  background-clip: padding-box;
  box-shadow: none !important;
}
.fw-bold {
  color: rgb(34, 35, 37) !important;
}
.fa-link {
  color: rgb(34, 35, 37) !important;
}
th {
  color: rgb(34, 35, 37);
  font-size: 16px;
  font-weight: 500;
  font-family: Macan, "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.text-items {
  color: #6f6b7d;
  text-align: left;
  font-size: .90rem;
  font-family: Public Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}
.text-size {
  font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 400 !important;
  font-style: normal !important;
  font-size: 15px !important;
  line-height: 22px !important;
  color: #a5a3ae !important;
}

.text-lorem {
  font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 500 !important;
  font-style: normal !important;
  font-size: 18px !important;
  line-height: 36px !important;
  color: rgb(93, 89, 108) !important;
}

.small-outline-button {
  background-color: transparent;
    color: #28a745;
    border: 2px solid #28a745;
    border-radius: 20px;
    padding: 1px 6px;
    font-size: 11px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
}

.small-outline-button:hover {
  background-color: #28a745;
  color: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transform: scale(1.05);
}

.small-outline-button:active {
  transform: scale(0.98);
  box-shadow: none;
}

.nav-pills .nav-link {
  font-weight: 500;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.nav-pills .nav-link.active {
  background-color: #7367f0;
  color: white;
}

.nav-link {
  color: black;
  height: 100%;
}

.no_mobile {
  border: 1px solid #7637f0;
  border-radius: 12px;
  width: 66%;
}

.textarea {
  height: 160px;
}

.login-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.vcip-options-div {
  display: flex;
  flex-wrap: wrap;
}

.vcip-options {
  background: #fff;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: all 0.3s;
  cursor: pointer;
}

.vcip-options:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.vcip-options-items {
  font-size: 18px;
  color: #555;
}

.vcip-options-items.good {
  font-weight: bold;
  margin: 10px 0;
}

.slider {
  width: 100%;
  margin-top: 10px;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #4CAF50;
  cursor: pointer;
}

.slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 6px;
  background: #ddd;
  border-radius: 3px;
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #4CAF50;
  cursor: pointer;
}

.slider::-moz-range-track {
  width: 100%;
  height: 6px;
  background: #ddd;
  border-radius: 3px;
}

.vcip-options {
  padding-left: 15px;
  padding-right: 15px;
}

body {
  background-color: #f5f5f5;
  font-family: Arial, sans-serif;
}

.login-title {
  text-align: center;
  font-size: 20px; /* Slightly smaller font size */
  margin-bottom: 15px;
}

.vcip-options {
  background: #f9f9f9;
  border-radius: 5px;
  padding: 10px;
}

.option-label {
  font-size: 14px;
  color: white !important
}

.value-display {
  color: white;
  font-size: 2rem;
}

.slider {
  width: 100%;
  height: 6px;
  background: #ddd;
  border-radius: 5px;
}

.slider::-webkit-slider-thumb {
  width: 16px; /* Smaller thumb */
  height: 16px;
  background: #7367f0;
  border-radius: 50%;
  cursor: pointer;
}

.save-button {
  width: 100%;
  padding: 8px;
  border: none;
  border-radius: 5px;
  background-color: #28a745;
  color: white;
  font-size: 14px; /* Smaller font size */
  cursor: pointer;
  margin-top: 15px;
}

.save-button:hover {
  background-color: #218838;
}

.vcip-options {
  background: #f9f9f9;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.vcip-options:hover {
  transform: translateY(-2px);
}

.row {
  margin-left: 0;
  margin-right: 0;
}

.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 8px;
  border-radius: 10px;
  background: #ddd;
  outline: none;
  transition: background 0.3s;
  margin-top: 10px;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px; 
  background: #a8c0ff !important;
  background: -webkit-linear-gradient(to right, #3f2b96, #a8c0ff) !important;
  background: linear-gradient(to right, #3f2b96, #a8c0ff) !important;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); 
  margin-top: -6px;
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #7367f0;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.slider::-ms-thumb {
  width: 20px;
  height: 20px;
  background: #7367f0;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.slider::-webkit-slider-runnable-track {
  border-radius: 10px; 
  background: #fff;
}

.slider::-moz-range-track {
  border-radius: 10px;
  background: #7367f0;
}

.slider::-ms-track {
  border-radius: 10px;
  background: transparent;
}

.slider-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.close {
  cursor: pointer;
  font-size: 24px;
  color: #7367f0;
  position: absolute;
  right: 20px;
  top: 20px;
}

.form-control {
  font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 400 !important;
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #a5a3ae !important;
}
.textAcco {
  font-size: 14px;
  color: #6f6b7d;
  padding: 0.5rem 0.5rem !important;
  border-width: 1px 1px 1px 5px;
  border-style: solid;
  border-color: #7367f0;
  border-image: initial;
  border-radius: 6px;
}
.list-group {
  width: 66%;
}
</style>
<template>
  <div class="chat-container">
    <div class="chat-header d-flex justify-content-between align-items-center">
      <div class="fw-bold fs-5">Chat Box</div>
      <div class="close-btn" @click="closePopup">✖</div>
    </div>

    <div class="chat-body">
      <div v-if="loading" class="spinner-container">
        <div class="spinner"></div>
      </div>

      <div v-else>
        <div
          v-for="message in messages"
          :key="message.key"
          :class="{
            'message-auditor': message.user !== username,
            'message-user': message.user === username
          }"
          class="message-bubble"
        >
          <div
            :class="{
              'bubble-user': message.user === username,
              'bubble-auditor': message.user !== username
            }"
          >
            <div class="message-text">{{ message.message }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- Input Section -->
    <div class="chat-input d-flex align-items-center">
      <input
        class="input-message"
        @input="onTyping"
        @keyup.enter="sendMessage"
        @blur="onStopTyping"
        v-model="newMessage"
        placeholder="Type a message..."
      />
      <i @click="sendMessage" class="fas fa-paper-plane send-icon"></i>
    </div>
  </div>
</template>

<script>
import { ref, push, update, onChildAdded } from "firebase/database";
import { database } from "@/firebase";
import eventBus from "../eventBus";

export default {
  data() {
    return {
      newMessage: "",
      currentTime: "",
      messages: [],
      loading: true, // Spinner will be shown initially
      username: localStorage.getItem("username") || "guest",
      group_id: this.$route?.query?.id || "default-group",
      typing: { user: "", typing: false }
    };
  },
  methods: {
    async sendMessage() {
      if (!this.newMessage) return;
      
      const messagesRef = ref(database, `groups/${this.group_id}`);
      push(messagesRef, {
        user: this.username,
        message: this.newMessage,
        // timestamp: serverTimestamp()
      });
      
      this.newMessage = "";
      this.onStopTyping();
    },
    onTyping() {
      const groupRef = ref(database, `groups/${this.group_id}`);
      update(groupRef, { [`typing/${this.username}`]: true });
    },
    onStopTyping() {
      const groupRef = ref(database, `groups/${this.group_id}`);
      update(groupRef, { [`typing/${this.username}`]: false });
    },
    closePopup() {
      eventBus.$emit("close", "closePopup");
    }
  },
  mounted() {
    const groupRef = ref(database, `groups/${this.group_id}`);

    onChildAdded(groupRef, (snapshot) => {
      const message = snapshot.val();
      if (message.message) {
        this.messages.push(message);
      }
    });

    setTimeout(() => {
      this.loading = false;
    }, 1500);
  }
};
</script>

<style scoped>
.chat-container {
  position: fixed;
  z-index: 1111;
  right: 20px;
  bottom: 20px;
  width: 360px;
  height: 500px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  font-family: 'Helvetica Neue', Arial, sans-serif;
  display: flex;
  flex-direction: column;
}

.chat-header {
  background-color: rgb(115, 103, 240);
  color: white;
  padding: 10px 15px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  font-weight: bold;
  font-size: 18px;
}

.close-btn {
  cursor: pointer;
  font-size: 18px;
}

.chat-body {
  padding: 15px;
  max-height: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: #f0f0f0;
}

.message-bubble {
  margin-bottom: 10px;
  display: flex;
}

.message-user {
  justify-content: flex-end;
}

.message-auditor {
  justify-content: flex-start;
}

.bubble-user, .bubble-auditor {
  padding: 8px 12px;
  max-width: 75%;
  font-size: 14px;
  line-height: 20px;
  position: relative;
}

.bubble-user {
  background-color: rgb(115, 103, 240);
  border-radius: 12px 12px 0px 12px;
  color: #fff;
}

.bubble-auditor {
  background-color: #fff;
  border-radius: 12px 12px 12px 0px;
  border: 1px solid #ececec;
  color: #333;
}

.chat-input {
  padding: 10px;
  border-top: 1px solid #ececec;
  background-color: #f5f5f5;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  display: flex;
  align-items: center;
}

.input-message {
  flex-grow: 1;
  padding: 8px 12px;
  border-radius: 20px;
  border: 1px solid #ececec;
  outline: none;
  background-color: #fff;
  border-color: rgb(115, 103, 240);
}

.send-icon {
  cursor: pointer;
  font-size: 20px;
  color: rgb(115, 103, 240);
  margin-left: 10px;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid rgb(115, 103, 240);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>

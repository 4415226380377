<template>
  <div class="col-md-3 d-flex flex-column flex-shrink-0 p-3 text-white bg-white bg-color" style="width: 280px;">
    <ul class="nav nav-pills flex-column mb-auto">
      <li class="mr-li">
        <a href="/basicDetails" :class="['custom-nav-link', { 'active-tab': activeTab === 'dashboard' }]"
          @click="setActiveTab('dashboard')" style="align-items: baseline">
          <i class="ti ti-home me-3"></i>
            Dashboard
        </a>
      </li>
      <li>
        <a href="#" :class="['custom-nav-link', { 'active-tab': activeTab === 'basic-details' }]"
          @click="setActiveTab('basic-details')" style="align-items: baseline">
          <i class="ti ti-layout-grid2 me-3" aria-hidden="true"></i>
          Basic Details
        </a>
      </li>
      <li>
        <a href="#" :class="['custom-nav-link', { 'active-tab': activeTab === 'additional-details' }]"
          @click="setActiveTab('additional-details')" style="align-items: baseline">
          <i class="ti ti-agenda me-3" aria-hidden="true"></i>
          Additional Details
        </a>
      </li>
    </ul>
    <hr>
    <!-- <div class="dropdown">
      <a href="#" class="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1"
        data-bs-toggle="dropdown" aria-expanded="false">
        <img src="https://github.com/mdo.png" alt="" width="32" height="32" class="rounded-circle me-2">
        <strong>mdo</strong>
      </a>
      <ul class="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1" style="">
        <li><a class="dropdown-item" href="#">New project...</a></li>
        <li><a class="dropdown-item" href="#">Settings</a></li>
        <li><a class="dropdown-item" href="#">Profile</a></li>
        <li>
          <hr class="dropdown-divider">
        </li>
        <li><a class="dropdown-item" href="#">Sign out</a></li>
      </ul>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "basicDetails",
  data() {
    return {
      activeTab: 'dashboard',
    };
  },
  mounted() {
    document.title = "Basic Details";
  },

  methods: {
    setActiveTab(tab) {
      this.activeTab = tab;
      if(tab==='basic-details'){
        this.$router.push("/basicDetails");
      } else if(tab==='additional-details') {
        this.$router.push("/additionalDetails");
      } else if(tab==='dashboard') {
        this.$router.push("/");
      }
    }
  },
};
</script>

<style scoped>
.custom-nav-link {
  background-color: white;
  color: #6f6b7d;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  transition: background-color 0.3s, color 0.3s;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.active-tab {
  background-color: #7367f0;
  color: white;
}

.custom-nav-link i {
  margin-right: 0.5rem;
}

.mr-li {
  margin: 0.125rem 0;
}
.bg-color {
  background-color: white !important;
  box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
  border-radius: 8px;
}

</style>
<template>
  <div>
    <TopNav />
    <section class="form-section">
      <div class="row justify-gap">
        <Sidenav />
        <div class="col-9 p-0 form-heading">
          <div class="progress-container">
            <ul class="progress-steps">
              <li class="step active">My Information</li>
              <li class="step active">Personal Info</li>
              <li class="step">Document verifications</li>
              <li class="step">Select Slot</li>
              <li class="step">Video KYC</li>
            </ul>

            <ul class="progress-indicators">
              <li class="indicator active"></li>
              <li class="indicator active"></li>
              <li class="indicator"></li>
              <li class="indicator"></li>
              <li class="indicator"></li>
            </ul>

            <div class="progress-bar-container">
              <div class="progress-bar"></div>
            </div>
          </div>
          <div class="form-container d-flex flex-column gap-3 p-4">
            <div class="card-title mb-0 me-1">
              <h5 class="mb-0 text-lorem">Lorem Ipsum</h5>
              <p class="mb-0 text-size">Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>
            </div>
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <div class="accordion-header" id="personalDetailsHeading">
                  <button class="accordion-button text-font" type="button" data-bs-toggle="collapse"
                    @click="toggleFlexDiv" data-bs-target="#collapseOne" aria-expanded="true"
                    aria-controls="collapseOne" data-bs-parent="#permanentAddressAccordion">
                    Personal Details
                  </button>
                </div>
                <div class="flex-row1">
                  <div id="personalDetailsCollapse" class="accordion-collapse collapse show"
                    aria-labelledby="personalDetailsHeading" data-bs-parent="#accordionExample">

                    <div class="row flex-row" :style="{ display: flexDivDisplay }">

                      <div class="col-md-6">
                        <div class="mb-3">
                          <label for="firstname" class="form-label email-mask">First Name</label>
                          <input type="text" class="form-control" id="firstname" v-model="personalDetails.name"
                            maxlength="30" placeholder="First Name" />
                          <span v-if="formSubmitted && !personalDetails.name" class="text-danger">First Name is
                            required</span>
                        </div>

                        <div class="mb-3">
                          <label for="lastname" class="form-label email-mask">Last Name</label>
                          <input type="text" class="form-control" id="lastname" v-model="personalDetails.lastname"
                            maxlength="30" placeholder="Last Name" />
                          <span v-if="formSubmitted && !personalDetails.lastname" class="text-danger">Last Name is
                            required</span>
                        </div>

                        <div class="mb-3">
                          <label for="dob" class="form-label email-mask">Date of Birth</label>
                          <input type="date" class="form-control" id="dob" v-model="personalDetails.dob"
                            @change="checkAge" :max="maxDate" />
                          <span v-if="formSubmitted && !personalDetails.dob" class="text-danger">Date of Birth is
                            required</span>
                          <p v-if="isUnder18" class="error-message text-danger">You must be 18 years or older.</p>
                        </div>
                      </div>

                      <div class="col-md-6 d-none">
                        <div class="tips-section p-3">
                          <h6 class="tips-title">Helpful Tips:</h6>
                          <div class="tip-item">
                            <p><strong>First Name:</strong> Enter your first name exactly as it appears on your official
                              documents to avoid discrepancies.</p>
                          </div>
                          <div class="tip-item">
                            <p><strong>Last Name:</strong> Ensure your last name matches your official records and is
                              free from spelling errors.</p>
                          </div>
                          <div class="tip-item">
                            <p><strong>Date of Birth:</strong> Make sure your date of birth is correct and matches your
                              identification documents.</p>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div class="accordion pb-3" id="accordionExample">
              <div class="accordion-item">
                <div class="accordion-header" id="personalDetailsHeading">
                  <button class="accordion-button text-font" type="button" data-bs-toggle="collapse"
                    @click="toggleFlexDiv2" data-bs-target="#collapseOne" aria-expanded="true"
                    aria-controls="collapseOne" data-bs-parent="#permanentAddressAccordion">
                    Permanent Address
                  </button>
                </div>
                <div id="personalDetailsCollapse" class="accordion-collapse collapse show"
                  aria-labelledby="personalDetailsHeading" data-bs-parent="#accordionExample">
                  <div class="accordion">
                    <div class="d-flex flex-row" :style="{ display: flexDivDisplay2 }">
                      <div class="accordion-collapse collapse show" aria-labelledby="personalDetailsHeading"
                        data-bs-parent="#accordionExample">
                        <div class="row" :style="{ display: flexDivDisplay2 }">
                          <div class="col-sm-4">
                            <div class="mb-3">
                              <label for="address1" class="form-label email-mask">Address Line 1</label>
                              <input type="text" class="form-control" id="address1"
                                v-model="permanentAddress.addressLine1" maxlength="50" placeholder="Address Line 1" />
                              <span v-if="
                                formSubmitted && !permanentAddress.addressLine1
                              " class="text-danger">required</span>
                            </div>
                          </div>
                          <div class="col-sm-4">
                            <div class="mb-3">
                              <label for="address2" class="form-label email-mask">Address Line 2</label>
                              <input type="text" class="form-control" id="address2"
                                v-model="permanentAddress.addressLine2" maxlength="50" placeholder="Address Line 2" />
                            </div>
                          </div>
                          <div class="col-sm-4">
                            <div class="mb-3">
                              <label for="address3" class="form-label email-mask">Address Line 3</label>
                              <input type="text" class="form-control" id="address3"
                                v-model="permanentAddress.addressLine3" maxlength="50" placeholder="Address Line 3" />
                            </div>
                          </div>
                          <div class="col-sm-4">
                            <div class="mb-3">
                              <label for="pincode" class="form-label email-mask">Pincode</label>
                              <input type="number" class="form-control" id="pincode" v-model="permanentAddress.pincode"
                                @input="enforceMaxLength" placeholder="Pincode" />
                              <span v-if="formSubmitted && !permanentAddress.pincode"
                                class="text-danger">required</span>
                            </div>
                          </div>
                          <div class="col-sm-4">
                            <div class="mb-3 d-flex flex-column position-relative">
                              <label for="city" class="form-label email-mask">City</label>
                              <div class="dropdown-container">
                                <input
                                  type="text"
                                  placeholder="Search City"
                                  class="form-control search-input"
                                  id="city"
                                  v-model="permanentAddress.city"
                                  @input="filterOptions"
                                  @focus="showDropdown = true"
                                />
                                <span
                                  v-if="showDropdown"
                                  class="close-icon"
                                  @click="closeDropdown"
                                >&#10005;</span>
                                <div class="dropdown-content" :class="{ show: showDropdown }">
                                  <a
                                    v-for="(option, index) in filteredOptions"
                                    :key="index"
                                    @click="selectOption(option)"
                                  >{{ option }}</a>
                                  <p v-if="filteredOptions.length === 0" class="no-results">No cities found</p>
                                </div>
                              </div>
                              <span
                                v-if="formSubmitted && !permanentAddress.city"
                                class="text-danger"
                              >City is required</span>
                            </div>
                          </div>
                          <!-- <div class="col-sm-4">
                            <div class="paddingTop pt-3">
                              <img src="../assets/wearealmost.png" alt="Description of the image" class="rounded6" />
                            </div>
                          </div> -->
                        </div>
                      </div>
                      <div>
                        <!-- <div class="paddingTop rounded5">
                <img
                  src="../assets/parmenet.png"
                  alt="Description of the image"
                  class="rounded5"
                />
              </div> -->
                      </div>
                    </div>

                    <!-- <div class="col-md-12 p-3 d-none" :style="{ display: flexDivDisplay2 }">
                        <div class="tips-section p-3">
                          <h6 class="tips-title">Helpful Tips:</h6>
                          <div class="tip-item">
                            <p><strong>Address Line 1:</strong> Ensure your address is accurate and matches your
                              official records. This is crucial for identity verification.</p>
                          </div>
                          <div class="tip-item">
                            <p><strong>Address Line 2 & 3:</strong> These fields are optional but can be used to provide
                              additional address details, such as apartment numbers or landmarks.</p>
                          </div>
                          <div class="tip-item">
                            <p><strong>Pincode:</strong> Double-check your pincode for accuracy to avoid delivery issues
                              and other location-based verifications.</p>
                          </div>
                          <div class="tip-item">
                            <p><strong>City:</strong> Enter the city as it appears on your official documents. Use the
                              dropdown to quickly find your city.</p>
                          </div>
                        </div>
                      </div> -->

                    <div :style="{ display: flexDivDisplay2 }" class="d-flex flex-column flex-row">
                      <h5 class="mb-0 text-lorem">Current Address</h5>
                      <label class="checkbox-label checkbox-type">
                        <input type="checkbox" id="sameaddress" :disabled="isDisabledCheckbox"
                          v-model="sameAsPermanent" />
                        Same as Permanent Address
                      </label>

                      <div class="row">
                        <div class="col-sm-4">
                          <div class="mb-3">
                            <label for="currentaddress1" class="form-label email-mask">Address Line 1</label>
                            <input type="text" class="form-control" id="currentaddress1"
                              v-model="currentAddress.addressLine1" :disabled="sameAsPermanent" maxlength="50"
                              placeholder="Address Line 1" />
                            <span v-if="formSubmitted && !currentAddress.addressLine1"
                              class="text-danger">required</span>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="mb-3">
                            <label for="currentaddress2" class="form-label email-mask">Address Line 2</label>
                            <input type="text" class="form-control" id="currentaddress2"
                              v-model="currentAddress.addressLine2" :disabled="sameAsPermanent" maxlength="50"
                              placeholder="Address Line 2" />
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="mb-3">
                            <label for="currentaddress3" class="form-label email-mask">Address Line 3</label>
                            <input type="text" class="form-control" id="currentaddress3"
                              v-model="currentAddress.addressLine3" :disabled="sameAsPermanent" maxlength="50"
                              placeholder="Address Line 3" />
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="mb-3">
                            <label for="currentpincode" class="form-label email-mask">Pincode</label>
                            <input type="number" class="form-control" id="currentpincode"
                              v-model="currentAddress.pincode" :disabled="sameAsPermanent" @input="enforceMaxLengthSec"
                              placeholder="Pincode" />
                            <span v-if="formSubmitted && !currentAddress.pincode" class="text-danger">required</span>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="mb-3">
                            <label for="currentcity" class="form-label email-mask">City</label>
                            <input type="text" class="form-control" id="currentcity" v-model="currentAddress.city"
                              :disabled="sameAsPermanent" placeholder="City" />
                            <span v-if="formSubmitted && !currentAddress.city" class="text-danger">required</span>
                          </div>
                        </div>

                        <!-- <div class="col-sm-6">
                <div class="mb-3">
                  <div class="login-button">
                    <button
                      type="submit"
                      class="btn btn-primary"
                      @click="updateCaseDetails()"
                    >
                      Next
                    </button>
                  </div>
                </div>
                <div class="loader-container" v-if="loader">
                  <div class="loader"></div>
                </div>
              </div> -->
                        <div>
                          <p class="error-message" v-if="errorMessage">
                            {{ errorMessage }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex" style="text-align: end; margin-top: 26px; justify-content: end;">
              <button type="button" class="btn btn-primary" @click="goBack()" style="margin-right: 14px; width: 25%">
                Back
              </button>
              <button type="submit" class="btn btn-primary" @click="updateCaseDetails()" style="width: 25%;">
                Next
              </button>
            </div>

            <!-- <div class="d-flex flex-row justify-content-between">
              <div class="col-sm-5">
                <div class="mb-3">
                  <div class="login-button">
                    <button type="button" class="btn btn-primary" @click="goBack()">
                      Back
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-sm-5">
                <div class="mb-3">
                  <div class="login-button">
                    <button type="submit" class="btn btn-primary" @click="updateCaseDetails()">
                      Next
                    </button>
                  </div>
                </div>
                <div class="loader-container" v-if="loader">
                  <div class="loader"></div>
                </div>
              </div>
            </div> -->

            <!-- <h2 class="accordion-header" id="personalDetailsHeading">
<button class="accordion-button" type="button" data-bs-toggle="collapse"  @click="toggleFlexDiv" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" data-bs-parent="#permanentAddressAccordion">
Permanent address
</button>
</h2>

 


  <div class="row accordion-collapse collapse show" aria-labelledby="personalDetailsHeading" data-bs-parent="#accordionExample" >
   <div class="row" :style="{ display: flexDivDisplay }">
   <div class="col-sm-6">
      <div class="mb-3">
        <label for="address1" class="form-label">Address Line 1</label>
        <input type="text" class="form-control" id="address1" v-model="permanentAddress.addressLine1"
          maxlength="50" />
        <span v-if="formSubmitted && !permanentAddress.addressLine1" class="text-danger">required</span>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="mb-3">
        <label for="address2" class="form-label">Address Line 2</label>
        <input type="text" class="form-control" id="address2" v-model="permanentAddress.addressLine2"
          maxlength="50" />
      </div>
    </div>
    <div class="col-sm-6">
      <div class="mb-3">
        <label for="address3" class="form-label">Address Line 3</label>
        <input type="text" class="form-control" id="address3" v-model="permanentAddress.addressLine3"
          maxlength="50" />
      </div>
    </div>
    <div class="col-sm-6">
      <div class="mb-3">
        <label for="pincode" class="form-label">Pincode</label>
        <input type="number" class="form-control" id="pincode" v-model="permanentAddress.pincode"
          @input="enforceMaxLength" />
        <span v-if="formSubmitted && !permanentAddress.pincode" class="text-danger">required</span>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="mb-3">
        <label for="city" class="form-label">City</label>
        <input type="text" class="form-control" id="city" v-model="permanentAddress.city" />
        <span v-if="formSubmitted && !permanentAddress.city" class="text-danger">required</span>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="paddingTop pt-3">
<img src="../assets/wearealmost.png" alt="Description of the image" class="rounded6">



</div>
    </div>
</div>
    

  </div>

  <h2>Current address</h2>

  <label class="checkbox-label">
    <input type="checkbox" id="sameaddress" :disabled="isDisabledCheckbox" v-model="sameAsPermanent" />
    Same as Permanent Address
  </label>

  <div class="row">
    <div class="col-sm-6">
      <div class="mb-3">
        <label for="currentaddress1" class="form-label">Address Line 1</label>
        <input type="text" class="form-control" id="currentaddress1" v-model="currentAddress.addressLine1"
          :disabled="sameAsPermanent" maxlength="50" />
        <span v-if="formSubmitted && !currentAddress.addressLine1" class="text-danger">required</span>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="mb-3">
        <label for="currentaddress2" class="form-label">Address Line 2</label>
        <input type="text" class="form-control" id="currentaddress2" v-model="currentAddress.addressLine2"
          :disabled="sameAsPermanent" maxlength="50" />
      </div>
    </div>
    <div class="col-sm-6">
      <div class="mb-3">
        <label for="currentaddress3" class="form-label">Address Line 3</label>
        <input type="text" class="form-control" id="currentaddress3" v-model="currentAddress.addressLine3"
          :disabled="sameAsPermanent" maxlength="50" />
      </div>
    </div>
    <div class="col-sm-6">
      <div class="mb-3">
        <label for="currentpincode" class="form-label">Pincode</label>
        <input type="number" class="form-control" id="currentpincode" v-model="currentAddress.pincode"
          :disabled="sameAsPermanent" @input="enforceMaxLengthSec" />
        <span v-if="formSubmitted && !currentAddress.pincode" class="text-danger">required</span>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="mb-3">
        <label for="currentcity" class="form-label">City</label>
        <input type="text" class="form-control" id="currentcity" v-model="currentAddress.city"
          :disabled="sameAsPermanent" />
        <span v-if="formSubmitted && !currentAddress.city" class="text-danger">required</span>
      </div>
    </div>

    <div class="col-sm-6">
      <div class="mb-3">
        <div class="login-button">
          <button type="submit" class="btn btn-primary" @click="updateCaseDetails()">
            Next
          </button>
        </div>
      </div>
      <div class="loader-container" v-if="loader">
        <div class="loader"></div>
      </div>
    </div>
    <div>
      <p class="error-message" v-if="errorMessage">
        {{ errorMessage }}
      </p>
    </div>
  </div> -->
          </div>
        </div>
      </div>
    </section>

    <footer>
      <div class="navbar-nav-footer">
        <a class="nav-item nav-link active" href="#">
          <span class="material-symbols-outlined"> border_color </span>
          <span>Apply</span>
        </a>
        <a class="nav-item nav-link" href="#">
          <span class="material-symbols-outlined"> group </span>
          <span>Users</span>
        </a>
        <a class="nav-item nav-link" href="#">
          <span class="material-symbols-outlined"> article </span>
          <span>Docs</span>
        </a>
        <a class="nav-item nav-link" href="#">
          <span class="material-symbols-outlined"> insert_chart </span>
          <span>Reports</span>
        </a>
        <a class="nav-item nav-link" href="#">
          <span class="material-symbols-outlined"> local_police </span>
          <span>Policy</span>
        </a>
      </div>
    </footer>
  </div>
</template>

<script>
import axios from "axios";
import TopNav from '@/components/topNav.vue';
import Sidenav from '@/components/sidenav.vue';

export default {
  name: "vcipDetails",
  data() {
    return {
      agree: false,
      flexDivDisplay: "flex!important",
      flexDivDisplay2: "flex!important",

      personalDetails: {
        name: "",
        lastname: "",
        dob: "",
      },
      permanentAddress: {
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        pincode: "",
        city: "",
      },
      currentAddress: {
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        pincode: "",
        city: "",
      },
      sameAsPermanent: false,
      formSubmitted: false,
      errorMessage: "",
      loader: false,
      isUnder18: false,
      maxDate: new Date().toISOString().split("T")[0],
      showFlexDiv: true,
      options: [
        "Mumbai",
        "Delhi",
        "Bangalore",
        "Kolkata",
        "Chennai",
        "Hyderabad",
        "Pune",
        "Ahmedabad",
        "Surat",
        "Jaipur",
        "Lucknow",
        "Kanpur",
        "Nagpur",
        "Visakhapatnam",
        "Indore",
        "Thane",
        "Bhopal",
        "Patna",
        "Vadodara",
        "Ghaziabad",
        "Ludhiana",
        "Agra",
        "Nashik",
        "Faridabad",
        "Meerut",
        "Rajkot",
        "Kalyan-Dombivali",
        "Vasai-Virar",
        "Varanasi",
        "Srinagar",
        "Aurangabad",
        "Dhanbad",
        "Amritsar",
        "Navi Mumbai",
        "Allahabad",
        "Ranchi",
        "Howrah",
        "Coimbatore",
        "Jabalpur",
        "Gwalior",
        "Vijayawada",
        "Jodhpur",
        "Madurai",
        "Raipur",
        "Kota",
        "Guwahati",
        "Chandigarh",
        "Solapur",
        "Hubballi-Dharwad",
        "Bareilly",
        "Moradabad",
        "Mysore",
        "Gurgaon",
        "Aligarh",
        "Jalandhar",
        "Tiruchirappalli",
        "Bhubaneswar",
        "Salem",
        "Warangal",
        "Guntur",
        "Bhiwandi",
        "Saharanpur",
        "Gorakhpur",
        "Bikaner",
        "Amravati",
        "Noida",
        "Jamshedpur",
        "Bhilai",
        "Cuttack",
        "Firozabad",
        "Kochi",
        "Nellore",
        "Bhavnagar",
        "Dehradun",
        "Durgapur",
        "Asansol",
        "Rourkela",
        "Nanded",
        "Kolhapur",
        "Ajmer",
        "Akola",
        "Gulbarga",
        "Jamnagar",
        "Ujjain",
        "Loni",
        "Siliguri",
        "Jhansi",
        "Ulhasnagar",
        "Nellore",
        "Jammu",
        "Sangli-Miraj & Kupwad",
        "Belgaum",
        "Mangalore",
        "Ambattur",
        "Tirunelveli",
        "Malegaon",
        "Gaya",
        "Jalgaon",
        "Udaipur",
        "Maheshtala",
        "Davanagere",
        "Kozhikode",
      ],

      searchText: "",
      showDropdown: false,
    };
  },
  components: {
    TopNav,
    Sidenav,
  },

  created() {
    this.getDataById();
  },
  mounted() {
    document.title = "Additional Details";

    const currentDate = new Date();
    const maxDate = new Date(
      currentDate.getFullYear() - 18,
      currentDate.getMonth(),
      currentDate.getDate()
    );

    const formattedMaxDate = maxDate.toISOString().split("T")[0];

    this.maxDate = formattedMaxDate;
  },

  computed: {
    isDisabledCheckbox() {
      return (
        !this.permanentAddress.addressLine1 ||
        !this.permanentAddress.addressLine2 ||
        !this.permanentAddress.addressLine3 ||
        !this.permanentAddress.pincode ||
        !this.permanentAddress.city
      );
    },

    filteredOptions() {
      const searchQuery = this.searchText.toLowerCase();
      return this.options.filter((option) =>
        option.toLowerCase().startsWith(searchQuery)
      );
    },
  },

  methods: {
    async updateCaseDetails() {
      this.formSubmitted = true;
      if (
        !this.personalDetails.name ||
        !this.personalDetails.lastname ||
        !this.personalDetails.dob ||
        !this.permanentAddress.addressLine1 ||
        !this.permanentAddress.addressLine2 ||
        !this.permanentAddress.addressLine3 ||
        !this.permanentAddress.pincode ||
        !this.permanentAddress.city
      ) {
        return;
      }

      const updateData = {
        personalDetails: this.personalDetails,
        permanentAddress: this.permanentAddress,
        currentAddress: this.sameAsPermanent
          ? this.permanentAddress
          : this.currentAddress,
        status: "Additional Details Done",
      };
      const id = this.$route.query.id;
      try {
        this.loader = true;
        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");
        const response = await axios.put(
          `${apiUrl}/case/update/${id}`,
          updateData
        );
        this.loader = false;
        this.navigateToVcipDetails(id);
        console.log("Update response:", response);
      } catch (error) {
        console.error("Update error:", error);
      }
    },
    checkAge() {
      if (this.personalDetails.dob) {
        const dobDate = new Date(this.personalDetails.dob);
        const currentDate = new Date();
        const age = currentDate.getFullYear() - dobDate.getFullYear();
        this.isUnder18 = age < 18;
        // if (this.isUnder18) {
        //   this.personalDetails.dob = ""
        //   setTimeout(() => this.isUnder18 = false, 2000)
        // }
      }
    },
    async getDataById() {
      try {
        const id = this.$route.query.id;
        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");
        const response = await axios.post(`${apiUrl}/case/getById/${id}`);
        const data = response.data;
        console.log("API Response:", data);
        this.personalDetails.name = data.personalDetails.name;
        this.personalDetails.lastname = data.personalDetails.lastname;
        this.personalDetails.dob = data.personalDetails.dob;
        this.permanentAddress.addressLine1 = data.permanentAddress.addressLine1;
        this.permanentAddress.addressLine2 = data.permanentAddress.addressLine2;
        this.permanentAddress.addressLine3 = data.permanentAddress.addressLine3;
        this.permanentAddress.pincode = data.permanentAddress.pincode;
        this.permanentAddress.city = data.permanentAddress.city;

        this.currentAddress.addressLine1 = data.currentAddress.addressLine1;
        this.currentAddress.addressLine2 = data.currentAddress.addressLine2;
        this.currentAddress.addressLine3 = data.currentAddress.addressLine3;
        this.currentAddress.pincode = data.currentAddress.pincode;
        this.currentAddress.city = data.currentAddress.city;
      } catch (error) {
        console.error("API Error:", error);
      }
    },

    enforceMaxLength() {
      const inputString = this.permanentAddress.pincode.toString();
      const cleanedInput = inputString.replace(/^0+|[^0-9]+/g, "");

      if (cleanedInput.length > 6) {
        this.permanentAddress.pincode = parseInt(cleanedInput.slice(0, 6), 10);
      } else {
        this.permanentAddress.pincode = parseInt(cleanedInput, 10);
      }
    },
    enforceMaxLengthSec() {
      const inputString = this.currentAddress.pincode.toString();
      const cleanedInput = inputString.replace(/^0+|[^0-9]+/g, "");
      if (cleanedInput.length > 6) {
        this.currentAddress.pincode = parseInt(cleanedInput.slice(0, 6), 10);
      } else {
        this.currentAddress.pincode = parseInt(cleanedInput, 10);
      }
    },
    navigateToVcipDetails(id) {
      this.$router.push("/vcipDetails?id=" + id);
    },
    toggleAccordion() {
      this.isAccordionOpen = !this.isAccordionOpen;
    },
    toggleFlexDiv() {
      this.flexDivDisplay =
        this.flexDivDisplay === "flex!important"
          ? "none!important"
          : "flex!important"; // Toggle the display property
    },
    toggleFlexDiv2() {
      this.flexDivDisplay2 =
        this.flexDivDisplay2 === "flex!important"
          ? "none!important"
          : "flex!important"; // Toggle the display property
    },

    goBack() {
      // Go back using Vue Router
      this.$router.go(-1);
    },
    filterOptions(event) {
      if (event.key === 'Enter') {
        // Check if the entered city exists in the options list
        if (!this.options.includes(this.searchText)) {
          // If it doesn't match any option, update permanent address city to the entered text
          this.permanentAddress.city = this.searchText;
        }
        // Hide the dropdown
        this.showDropdown = false;
      } else {
        // Filter the options based on the entered search text
        const searchQuery = this.searchText.toLowerCase();
        this.filteredOptions = this.options.filter(option =>
          option.toLowerCase().startsWith(searchQuery)
        );
        // Show the dropdown
        this.showDropdown = true;
      }
    },

    selectOption(option) {
      // Update permanent address city
      this.permanentAddress.city = option;
      // Set searchText to the selected option
      this.searchText = option;
      // Hide the dropdown
      this.showDropdown = false;
    },

    closeDropdown() {
      // Check if the entered city exists in the options list
      if (!this.options.includes(this.searchText)) {
        // If it doesn't match any option, update permanent address city to the entered text
        this.permanentAddress.city = this.searchText;
      }
      // Hide the dropdown
      this.showDropdown = false;
    },

  },

  watch: {
    // sameAsPermanent(value) {
    //   if (value) {
    //     this.currentAddress = { ...this.permanentAddress };
    //   }
    // },
    sameAsPermanent(value) {
      if (value) {
        // If sameAsPermanent is checked, set currentAddress equal to permanentAddress
        this.currentAddress = { ...this.permanentAddress };
        // Update permanentAddress city based on the selected option or entered text
        if (!this.options.includes(this.searchText)) {
          this.permanentAddress.city = this.searchText;
        }
      }
    },

  },
};
</script>

<!-- Add relevant HTML code here -->

<style scoped>
#sameaddress:checked {
  access-color: black;
}

.error-message {
  color: red;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background overlay */
  z-index: 999;
  /* Ensure the overlay is on top */
}

.loader {
  border: 1px solid #f3f3f3;
  border-top: 1px solid #3498db;
  border-radius: 100px;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

h2 {
  font-weight: 600;
  font-size: 1rem;
  margin-top: 2rem;
  color: black;
}

.form-label {
  margin-bottom: 0.2rem;
}

.form-control {
  background-color: white;
  font-size: 14px;
}

.process-chart-mobile {
  display: none;
}

.navbar {
  padding: 0.5rem 3rem;
}

.navbar-brand {
  font-size: xx-large;
  font-weight: 600;
}

.nav-item {
  align-items: center;
  font-size: small;
}

.form-section {
  position: relative;
  padding: 1rem;
  background-color: #f8f7fa;
  min-height: 100vh;
}

.heading-title-circle,
.heading-title {
  padding-right: 2rem;
}

.b-w-msg {
  text-align: start;
  font-size: x-large;
  font-weight: bold;
  margin-top: 3rem;
}

.page-form {
  padding-block: 2rem;
  padding-inline: 4rem;
}

.form-heading {
  padding-left: 20px;
  background-color: white;
  border-radius: 8px;
}

.progress {
  height: 3px;
  margin: -27px 2rem 20px 2rem;
  background-color: rgb(184, 183, 183);
}

.progress-bar {
  width: 100%;
  background-color: black;
}

.process-chart {
  padding: 15px;
}

.input-2 {
  display: flex;
}

.b-box {
  margin: 0px auto;
  width: 30%;
  margin-bottom: 20px;
}

.heading-title {
  color: black;
  list-style: none;
  display: flex;
  justify-content: space-between;
  font-size: x-large;
  font-weight: 600;
}

.form-check-label {
  color: black;
  /* Change the color to your preference */
}

.heading-title-circle {
  display: flex;
  justify-content: space-between;
  list-style: none;
}

.vcip {
  margin-left: 4rem;
}

.btn.btn-primary {
  width: 100%;
  background-color: #7367f0;
  border: 2px solid #7367f0;
  margin-top: 1.5rem;
}

.nav-item {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

form {
  padding: 2rem;
  padding-top: 0rem;
}

.heading-title-circle li {
  width: 17px;
  height: 17px;
  background-color: white;
  border: 2px solid grey;
  border-radius: 50%;
  z-index: 1;
}

.heading-title-circle li.active {
  border: 5px solid black;
  border-radius: 50%;
}

.v-t {
  margin-top: 10px;
  margin-bottom: 2px;
  text-align: start;
}

.form-check {
  display: flex;
  gap: 10px;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

.input-box .input-1 {
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  color: #202124;
  border: 1px solid rgb(0, 0, 0, 0.6);
  padding: 15px;
  transition: 250ms;
}

.btn.btn-primary {
  margin-bottom: 0.85rem;
}

#agreeCheckbox:checked {
  accent-color: #202124;
}

.checkbox-label {
  margin-bottom: 1rem;
}

footer {
  display: none;
}

@media (max-width: 768px) {
  .navbar {
    display: none;
  }

  h2 {
    font-weight: bold;
    font-size: medium;
    color: black;
    margin-block: 2rem;
  }

  .form-section {
    position: relative;
    padding: 0px 0px;
    background-color: white;
  }

  .process-chart {
    display: none;
  }

  .form-container {
    padding-top: 8rem;
    padding-bottom: 4rem;
    padding-left: 3rem;
    padding-right: 3rem;
  }

  form {
    padding-inline: 0.5rem;
  }

  .b-w-msg {
    margin-top: 1rem;
  }

  .v-t {
    margin-top: 1rem;
  }

  .heading-title-circle,
  .heading-title {
    padding-left: 1.2rem;
  }

  .process-chart-mobile {
    display: block;
    position: fixed;
    background-color: rgb(244, 244, 244, 0.8);
    z-index: 1;
    width: 100%;
  }

  .heading-title li {
    font-size: xx-large;
    margin-top: 2rem;
  }

  .heading-title-circle li {
    width: 17px;
  }

  .progress {
    height: 3px;
    margin: -27px 19rem 20px 0rem;
    background-color: rgb(184, 183, 183);
  }

  .btn-submit-div {
    position: absolute;
    bottom: 1rem;
    left: 2rem;
    right: 2rem;
  }

  .heading-title-circle .li3 {
    margin-left: 2rem;
  }

  .form-check-label {
    font-size: 1.1rem;
  }

  .form-check {
    margin-block: 1rem;
  }

  .btn.btn-primary {
    margin-bottom: 0.5rem;
    margin-top: 1rem;
  }

  .vcip {
    margin-left: -6rem;
  }

  footer {
    display: block;
    height: 100%;
  }

  .navbar-nav-footer {
    display: flex;
    column-gap: 22px;
    justify-content: space-between;
    flex-direction: row;
    position: fixed;
    padding: 1rem;
    bottom: 0;
    background-color: rgb(244, 244, 244, 0.8);
    width: 100%;
  }

  .navbar-nav-footer .nav-link {
    color: grey;
  }

  .navbar-nav-footer .nav-link :nth-child(2) {
    font-size: small;
    font-weight: bold;
  }

  .navbar-nav-footer .nav-link.active {
    color: black;
  }

  .material-symbols-outlined {
    font-size: 34px;
  }
}

.rounded5 {
  border-radius: 20px;
  width: 345px;
}

.rounded6 {
  border-radius: 20px;
  width: 385px;
}

.btn.btn-primary[data-v-428cba8a] {
  width: 100%;
  background-color: #7367f0;
  border: 2px solid #7367f0;
  margin-top: 0rem !important;
}

.width80 {
  width: 81.2vw;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 200px;
  border: 1px solid #ddd;
  z-index: 1;
  max-height: 200px;
  overflow-y: auto;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.show {
  display: block;
}

/* .search-input {
      width: 100%;
      padding: 8px;
      margin-bottom: 4px;
      box-sizing: border-box;
    } */
.close-icon {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  cursor: pointer;
}

.search-input[data-v-428cba8a] {
  width: 100%;
  padding: 7px;
  margin-bottom: 4px;
  box-sizing: border-box;
  border-radius: 6px;
}

.justify-gap {
  display: flex;
  justify-content: space-evenly;
}

.accordion-button {
  background-color: #e2e2e2 !important;
  color: black !important;
}

.text-font {
  font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 500 !important;
  font-style: normal !important;
  font-size: 18px !important;
  line-height: 36px !important;
  color: rgb(93, 89, 108) !important;
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.text-lorem {
  font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 500 !important;
  font-style: normal !important;
  font-size: 18px !important;
  line-height: 36px !important;
  color: rgb(93, 89, 108) !important;
}

.text-size {
  font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 400 !important;
  font-style: normal !important;
  font-size: 15px !important;
  line-height: 22px !important;
  color: #a5a3ae !important;
}

.email-mask {
  margin-bottom: 0.25rem !important;
  font-size: 0.8125rem !important;
  color: rgb(93, 89, 108);
  font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 500 !important;
  font-style: normal !important;
}

.checkbox-type {
  margin-bottom: 0.25rem !important;
  font-size: 0.8125rem !important;
  color: #5d596c;
  margin-top: 0.25rem;
  display: flex;
  align-items: center;
  gap: 8px;
}

.flex-row {
  padding: 1rem !important;
}

/* Styling for form fields */
.form-label {
  font-weight: 600;
  color: #333;
}

.form-control {
  border: 1px solid #ced4da;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
}

.text-danger {
  font-size: 13px;
  margin-top: 5px;
}

.form-text {
  font-size: 12px;
  color: #6c757d;
}

/* Styling for tips section */
.tips-section {
  background-color: #f8f9fa;
  border-radius: 5px;
  border: 1px solid #dee2e6;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-left: 4px solid #7367f0;
}

.tips-title {
  font-size: 16px;
  font-weight: 600;
  color: #495057;
  margin-bottom: 10px;
}

.tip-item {
  font-size: 14px;
  color: #495057;
  line-height: 1.6;
  margin-bottom: 15px;
}

/* Container Styles */
.progress-container {
  width: 100%;
  margin: 0;
  text-align: center;
  padding: 20px;
}

/* Step Titles */
.progress-steps {
  list-style: none;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0;
}

.progress-steps .step {
  font-size: 14px;
  font-weight: 500;
  color: #9e9e9e;
  position: relative;
}

.progress-steps .active {
  color: #7367f0; /* Highlight active step */
  font-weight: 600;
}

/* Step Indicators */
.progress-indicators {
  list-style: none;
  display: flex;
  justify-content: space-between;
  margin: 0 10px;
  padding: 0;
}

.progress-indicators .indicator {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #e0e0e0;
}

.progress-indicators .active {
  background-color: #7367f0; /* Green for active step */
}

/* Progress Bar */
.progress-bar-container {
  width: 100%;
  height: 8px;
  background-color: #e0e0e0;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

.progress-bar {
  width: 26%; /* Adjust width dynamically based on the current step */
  height: 100%;
  background-color: #7367f0;
  transition: width 0.3s ease-in-out;
}
.dropdown-container {
  position: relative;
  width: 100%;
}

/* Search Input Styling */
.search-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 8px;
  font-size: 16px;
  transition: border-color 0.3s;
}

.search-input:focus {
  outline: none;
  border-color: #4caf50; /* Green border on focus */
  box-shadow: 0 0 5px rgba(76, 175, 80, 0.2);
}

/* Close Icon Styling */
.close-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  font-size: 18px;
  color: #6c757d;
  cursor: pointer;
}

/* Dropdown Content Styling */
.dropdown-content {
  position: absolute;
  top: 110%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  display: none;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

/* Show Dropdown */
.dropdown-content.show {
  display: block;
  opacity: 1;
  transform: translateY(0);
}

/* Dropdown Item Styling */
.dropdown-content a {
  padding: 12px 15px;
  display: block;
  font-size: 16px;
  color: #212529;
  text-decoration: none;
  transition: background-color 0.2s;
  cursor: pointer;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

/* No Results Text */
.no-results {
  padding: 12px 15px;
  font-size: 16px;
  color: #6c757d;
  text-align: center;
}
</style>

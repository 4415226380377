<template>
  <div>
    <TopNav />
    <section class="form-section">
      <div class="row d-flex display-gap">
        <Sidenav />
        <div class="col-md-9 p-0 form-heading card p-0 mb-0 border-color">
          <div class="progress-container">
            <ul class="progress-steps">
              <li class="step active">My Information</li>
              <li class="step">Personal Info</li>
              <li class="step">Document verifications</li>
              <li class="step">Select Slot</li>
              <li class="step">Video KYC</li>
            </ul>

            <ul class="progress-indicators">
              <li class="indicator active"></li>
              <li class="indicator"></li>
              <li class="indicator"></li>
              <li class="indicator"></li>
              <li class="indicator"></li>
            </ul>

            <div class="progress-bar-container">
              <div class="progress-bar"></div>
            </div>
      </div>
          <div class="process-chart-mobile">
          <ul class="heading-title">
              <li class="">My Information</li>
              <li class="">V</li>
            </ul>
            <ul class="heading-title-circle">
              <li class="li1 active"></li>
              <li class="li2"></li>
            </ul>
            <div class="progress mobile">
              <div class="progress-bar mobile"></div>
            </div>
          </div>

          <div class="container">
            <div class="row form-container">
              <div class="col-sm-12 d-flex flex-row gap-5 pb-5 p-4">
                <div class="6">
                  <h5 class="mb-0 text-font">Welcome to vCIP</h5>
                  <p class="mb-4 text-size">Please ensure all fields are filled correctly. Thank you!</p>
                  <div class="mb-3 eight">
                    <label for="email-mask" class="form-label email-mask">Mobile No.</label>
                    <div class="input-group mb-3">
                      <input type="number" id="currentaddress1" class="form-control input-text" v-model="mobileNo"
                        @input="enforceMaxLength" :disabled="forVarify || forSubmit" placeholder="Mobile Number" />
                    </div>
                  </div>
                  <div class="mb-3 eight">
                    <label for="email-mask" class="form-label email-mask">Email Address</label>
                    <div class="input-group mb-3">
                      <input type="email" class="form-control input-text" id="currentaddress3" v-model="email"
                        @keyup="validateEmail" :disabled="forVarify || forSubmit" placeholder="Email Address" />
                    </div>

                    <p class="error-message" v-if="errorMess">
                      {{ errorMess }}
                    </p>
                  </div>
                  <div v-show="isHidden" class="mb-3 hide-otp">
                    <label for="currentaddress2" class="form-label email-mask">OTP</label>
                    <input type="number" class="form-control input-text" id="currentaddress2" @input="otpMaxLength"
                      v-model="otp" placeholder="OTP" />
                    <div class=" mt-1">
                      <p class="error-message" v-if="errorOtp" id="error-otp">
                        {{ errorOtp }}
                      </p>
                    </div>
                    <div class=" d-flex justify-content-end mt-2">
                      <a href="#" class="link-muted" v-if="showButton" @click.prevent="resendOtp">Resend Otp</a>
                    </div>
                  </div>
                  <p class="error-message" v-if="errorMessage" id="error-mas">
                    {{ errorMessage }}
                  </p>
                  <div class="">
                    <div v-if="isUnHidden" class="login-button">
                      <button type="submit" class="btn btn-primary" :disabled="!mobileNo ||
                        mobileNo.toString().length < 10 ||
                        !isValidEmail || otpSent
                        " @click="clickSendOtp">
                        Send OTP
                      </button>
                    </div>
                    <div class="mb-3" v-if="forSubmit">
                      <label for="sumbit">
                        <input type="checkbox" class="checkbox" v-model="agree" />
                        <span>
                          I agree to the
                          <a href="#" class="link-muted" style="text-decoration: none"
                            @click="navigateToTermAndCon">Terms
                            & Conditions</a>
                        </span>
                      </label>
                    </div>
                    <div v-if="forVarify || forSubmit" class="login-button d-flex flex-row gap-2">

                      <button class="btn btn-primary" @click="goBack" style="margin-right: 10px;">
                        Back
                      </button>
                      <button class="btn btn-primary" v-if="showButton" :disabled="!otp || otp.toString().length < 6"
                        @click="clickVerifyOtp">
                        Verify OTP
                      </button>

                      <button v-if="forSubmit" type="submit" class="btn btn-primary" :disabled="!agree ||
                        !mobileNo ||
                        mobileNo.toString().length < 10 ||
                        !otp ||
                        otp.toString().length < 6 ||
                        !email ||
                        !isValidEmail
                        " @click="submitData()">
                        Submit
                      </button>
                    </div>
                  </div>
                  <!-- <div class="" v-if="forSubmit">
                  <label for="sumbit">
                    <input type="checkbox" class="checkbox" v-model="agree" />
                    <span>
                      I agree to the
                      <a
                        href="#"
                        style="text-decoration: none"
                        @click="navigateToTermAndCon"
                        >Terms & Conditions</a
                      >
                    </span>
                  </label>
                </div> -->
                </div>
                <div class="col-md-6 d-none">
                  <div class="tips-section p-4 shadow-sm rounded"
                    style="border-left: 4px solid #7367f0; background-color: #f9f9ff;">
                    <h6 class="tips-title" style="color: #7367f0; font-weight: bold;">Helpful Tips:</h6>

                    <div class="tip-item mb-3">
                      <p><strong>Mobile No.:</strong> Ensure your mobile number is entered correctly and is currently
                        active. This number will be used for receiving OTPs and important updates.</p>
                    </div>

                    <div class="tip-item mb-3">
                      <p><strong>Email Address:</strong> Double-check your email address for accuracy. A valid email is
                        essential for communication and verification purposes.</p>
                    </div>

                    <div class="tip-item mb-3">
                      <p><strong>OTP:</strong> Enter the OTP sent to your mobile number. If you haven't received it, you
                        can request to resend the OTP after a short wait.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="loader-container" v-if="loader">
      <div class="loader"></div>
    </div>

    <footer>
      <div class="navbar-nav-footer">
        <a class="nav-item nav-link active" href="#">
          <span class="material-symbols-outlined"> border_color </span>
          <span>Apply</span>
        </a>
        <a class="nav-item nav-link" href="#">
          <span class="material-symbols-outlined"> group </span>
          <span>Users</span>
        </a>
        <a class="nav-item nav-link" href="#">
          <span class="material-symbols-outlined"> article </span>
          <span>Docs</span>
        </a>
        <a class="nav-item nav-link" href="#">
          <span class="material-symbols-outlined"> insert_chart </span>
          <span>Reports</span>
        </a>
        <a class="nav-item nav-link" href="#">
          <span class="material-symbols-outlined"> local_police </span>
          <span>Policy</span>
        </a>
      </div>
    </footer>
  </div>
</template>

<script>
// const message = document.getElementsByClassName("hide-otp");
// message.style.display="none"
import axios from "axios";
// import { urlToHttpOptions } from "url";
import Sidenav from '@/components/sidenav.vue';
import TopNav from '@/components/topNav.vue';

export default {
  name: "basicDetails",
  data() {
    return {
      otpSent: false,
      forVarify: false,
      showButton: true,
      forSubmit: false,
      isHidden: false,
      activeTab: 'dashboard',
      isUnHidden: true,
      agree: false,
      mobileNo: "",
      otp: "",
      responseOtp: "",
      email: "",
      loader: false,
      isValidEmail: false,
      validRegex:
        /^[a-zA-Z0-9._%+-]+@[\w.-]+\.[a-zA-Z]{2,}$/
      ,
      errorMessage: "",
      errorOtp: "",
      errorMess: "",
      isValidMobile: false, // Add this line
    };
  },
  components: {
    Sidenav,
    TopNav,
  },
  watch: {
    email: function () {
      this.isValidEmail = this.validRegex.test(this.email);
    },

    mobileNo: function () {
      this.isValidMobile = this.mobileNo.toString().length === 10;
    },

  },
  mounted() {
    document.title = "Basic Details";
  },

  methods: {
    async submitData() {
      this.loader = true;
      const data = {
        mobileNo: this.mobileNo,
        otp: this.otp,
        email: this.email,
        status: "Basic Detail Done"
      };

      if (this.isValidEmail) {
        console.log("Form submitted!");
      } else {
        this.errorMess = "Please enter a valid Gmail address.";
        console.log("invalid mail");
      }

      try {
        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");
        const response = await axios.post(`${apiUrl}/case/createCase`, data);
        console.log(response, "response");
        if (response.status === 201) {
          this.loader = false;
          const userId = localStorage.getItem('userId');
          // this.navigateToVcipDetails(response.data._id);
          this.navigateToAdditionalDetails(userId);
        } else {
          this.loader = false;
          this.errorMessage = "An error occurred during case creation";
        }
      } catch (error) {
        this.loader = false;
        this.errorMessage =
          "An error occurred while communicating with the server";
      }
    },
    navigateToVcipDetails(id) {
      this.$router.push("/vcipDetails?id=" + id);
    },
    // navigateToTermAndCon() {
    //   this.$router.push("/termAndCon");
    // },
    navigateToAdditionalDetails(id) {
      this.$router.push("/additionalDetails?id=" + id)

    },
    logout() {
      localStorage.clear()
      //await this.$keycloak?.logout()
      this.$router.push('/')
    },
    enforceMaxLength() {
      const inputString = this.mobileNo.toString();
      const cleanedInput = inputString.replace(/^0+|[^0-9]+/g, "");

      if (cleanedInput.length > 10) {
        this.mobileNo = parseInt(cleanedInput.slice(0, 10), 10);
      } else {
        this.mobileNo = parseInt(cleanedInput, 10);
      }
    },
    otpMaxLength() {
      const inputOtpString = this.otp.toString();
      const clearInput = inputOtpString.replace(/^0+|[^0-9]+/g, "");

      if (clearInput.length > 6) {
        this.otp = parseInt(clearInput.slice(0, 6), 10);
      } else {
        this.otp = parseInt(clearInput, 10);
      }
    },
    async clickSendOtp() {
      this.loader = true;
      this.forVarify = false;
      this.isHidden = false;
      const data = {
        mobileNo: this.mobileNo,
        email: this.email,
      };
      try {
        // const scriptElement = document.querySelector("script[api-url]");
        // const apiUrl = scriptElement.getAttribute("api-url");
        // // First, check if the mobile number already exists
        // const checkMobileNumberResponse = await axios.post(`${apiUrl}/case/createCase`, { mobileNo: this.mobileNo });
        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");
        const checkMobileNumberResponse = await axios.post(`${apiUrl}/case/createCase`, data);
        console.log("checkMobileNumberResponse", checkMobileNumberResponse);
        if (checkMobileNumberResponse.data.message === "User successfully fill the details") {
          localStorage.setItem('userId', checkMobileNumberResponse.data._id);
          const sendOtpResponse = await axios.post(`${apiUrl}/case/sendOtpData`, data);
          if (sendOtpResponse.data.status === "success") {
            this.loader = false;
            this.responseOtp = sendOtpResponse.data.data;
            console.log(this.responseOtp);
          }
          this.isHidden = true;
          this.isUnHidden = false;
          this.otpSent = true;
          this.forVarify = true;
        } else if (checkMobileNumberResponse.data.message === "Mobile number already exists") {
          this.errorMessage = "Mobile number already exists";
          setTimeout(() => {
            this.errorMessage = "";
          }, 2000);
          this.loader = false;
          this.otpSent = false;
          this.isHidden = false;
          this.isUnHidden = true;
          setTimeout(() => {
            this.errorMess = "";
          }, 2000);
        } else {
          this.loader = false;
          this.errorMess = "Email already exists";
          setTimeout(() => {
            this.errorMess = "";
          }, 2000);
          this.loader = false;
          this.otpSent = false;
          this.isHidden = false;
          this.isUnHidden = true;
          setTimeout(() => {
            this.errorMess = "";
          }, 2000);
        }
      } catch (error) {
        this.loader = false;
        console.error("API call error:", error);
      }
    },

    // clickVerifyOtp() {
    //   debugger
    //   console.log(this.otp);
    //   console.log(this.responseOtp);

    //   if (this.otp.toString() === this.responseOtp.toString()) {
    //     this.forVarify = false;
    //     this.forSubmit = true;
    //   } else {
    //     this.errorOtp = "Invalid OTP";
    //     setTimeout(() => {
    //       this.errorOtp = "";
    //     }, 2000);
    //   }
    // },

    clickVerifyOtp() {
      const hardcodedOtp = "123456";

      if (this.otp.toString() === hardcodedOtp) {
        this.forVerify = false;
        this.showButton = false,
          this.forSubmit = true;
      } else {
        this.errorOtp = "Invalid OTP";
        setTimeout(() => {
          this.errorOtp = "";
        }, 2000);
      }
    },

    goBack() {
      // Refresh the page
      window.location.reload();

      // Reset the component state
      this.forVarify = false;
      this.forSubmit = false;
      this.isUnHidden = true;
      this.isHidden = false;
      this.showButton = true;

      // Reset OTP and error messages
      this.otp = "";
      this.errorOtp = "";
      this.errorMessage = "";

      // Recheck mobile and email validity
      this.isValidEmail = this.validRegex.test(this.email);
      this.isValidMobile = this.mobileNo.toString().length === 10;
    },

    async resendOtp() {
      this.loader = true
      const data = {
        email: this.email,
      };
      try {
        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");
        const response = await axios.post(`${apiUrl}/case/sendOtpData`, data);
        console.log(response.data, "response");
        console.log(response.data.status);
        if (response.data.status === "success") {
          this.loader = false;
          this.responseOtp = response.data.data;
          console.log(this.responseOtp);
        }
        this.loader = false;
      } catch (error) {
        this.loader = false;
        console.error("API call error:", error);
      }


    }, validateEmail() {
      if (this.isValidEmail) {
        this.errorMessage = "";
        console.log("Form submitted!");
      } else {
        this.errorMessage = "Please enter a valid Gmail address.";
        console.log("invalid mail");
      }

    },
    setActiveTab(tab) {
      this.activeTab = tab;
    }
  },
};
</script>

<style scoped>
h2 {
  font-weight: 600;
  font-size: 1rem;
  margin-top: 2rem;
  color: black;
}

.form-label {
  margin-bottom: 0.2rem;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.422rem 0.875rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6f6b7d;
  appearance: none;
  background-color: #fff;
  background-clip: padding-box;
  border: var(--bs-border-width) solid #dbdade;
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.process-chart-mobile {
  display: none;
}

.navbar {
  padding: 0.5rem 3rem;
}

.navbar-brand {
  font-size: xx-large;
  font-weight: 600;
}

.nav-item {
  align-items: center;
  font-size: small;
}

.form-section {
  position: relative;
  padding: 1rem;
  background-color: #f8f7fa;
  min-height: 100vh;
}

.heading-title-circle,
.heading-title {
  padding-right: 2rem;
}

.b-w-msg {
  text-align: start;
  font-size: x-large;
  font-weight: bold;
  margin-top: 3rem;
}

.row {
  justify-content: center;
}

.page-form {
  padding-block: 2rem;
  padding-inline: 4rem;
}

.form-heading {
  padding-left: 20px;
  background-color: white;
  border-radius: 8px;
  margin-bottom: 100px;
}

.progress {
  height: 3px;
  margin: -27px 2rem 20px 2rem;
  background-color: rgb(184, 183, 183);
}

.progress-bar {
  width: 50%;
  background-color: #7367f0;
}

.process-chart {
  padding: 15px;
}

.input-2 {
  display: flex;
}

.b-box {
  margin: 0px auto;
  width: 30%;
  margin-bottom: 20px;
}

.heading-title {
  color: black;
  list-style: none;
  display: flex;
  justify-content: space-between;
  font-size: x-large;
  font-weight: 600;
}

.form-check-label {
  color: black;
  /* Change the color to your preference */
}

.heading-title-circle {
  display: flex;
  justify-content: space-between;
  list-style: none;
}

.vcip {
  margin-left: 4rem;
}

.nav-item {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

form {
  padding: 2rem;
  padding-top: 0rem;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading-title-circle li {
  width: 17px;
  height: 17px;
  background-color: white;
  border: 2px solid grey;
  border-radius: 50%;
  z-index: 1;
}

.heading-title-circle li.active {
  border: 5px solid #7367f0;
  border-radius: 50%;
}

.v-t {
  margin-top: 10px;
  margin-bottom: 2px;
  text-align: start;
}

.form-check {
  display: flex;
  gap: 10px;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

.input-box .input-1 {
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  color: #202124;
  border: 1px solid rgb(0, 0, 0, 0.6);
  padding: 15px;
  transition: 250ms;
}

.btn.btn-primary {
  margin-bottom: 0.85rem;
}

#agreeCheckbox:checked {
  accent-color: #202124;
}

.checkbox-label {
  margin-bottom: 1rem;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background overlay */
  z-index: 999;
  /* Ensure the overlay is on top */
}

.loader {
  border: 1px solid #f3f3f3;
  border-top: 1px solid #3498db;
  border-radius: 100px;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

footer {
  display: none;
}

@media (max-width: 768px) {
  .navbar {
    display: none;
  }

  h2 {
    font-weight: bold;
    font-size: large;
    color: black;
    margin-block: 2rem;
  }

  .form-section {
    position: relative;
    padding: 0px 0px;
    background-color: white;
    min-height: auto;
  }



  .process-chart {
    display: none;
  }

  .form-container {
    min-height: 70vh;
    margin-top: 9rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  form {
    padding-inline: 0.5rem;
  }

  .b-w-msg {
    margin-top: 1rem;
  }

  .v-t {
    margin-top: 1rem;
  }

  .heading-title-circle,
  .heading-title {
    padding-left: 1.2rem;
  }

  .process-chart-mobile {
    display: block;
    position: fixed;
    background-color: rgb(244, 244, 244);
    z-index: 1;
    width: 100%;
    top: 0;
  }

  .heading-title-circle,
  .heading-title {
    padding-right: 0rem;
    padding-left: 1rem;
  }

  .heading-title li {
    font-size: xx-large;
    padding: 1rem;
    margin-right: -1.5rem;
  }

  .heading-title-circle li {
    width: 17px;
    margin-left: 1rem;
    margin-right: -0.5rem;
  }

  .progress.mobile {
    height: 3px;
    margin: -27px 19rem 20px 2rem;
    background-color: rgb(184, 183, 183);
    width: 90%;
  }

  .progress-bar.mobile {
    width: 100%;
  }

  .btn-submit-div {
    position: absolute;
    bottom: 1rem;
    left: 2rem;
    right: 2rem;
  }

  .heading-title-circle .li3 {
    margin-left: 2rem;
  }

  .form-check-label {
    font-size: 1.1rem;
  }

  .form-check {
    margin-block: 1rem;
  }

  .btn.btn-primary {
    margin-bottom: 0.5rem;
    margin-top: 1rem;
  }

  .vcip {
    margin-left: -6rem;
  }

  footer {
    display: block;
    height: 100%;
  }

  .submit-btn-checbox {
    margin-top: 11rem;
  }

  .navbar-nav-footer {
    display: flex;
    column-gap: 22px;
    justify-content: space-between;
    flex-direction: row;
    position: fixed;
    padding: 1rem;
    bottom: 0;
    background-color: rgb(244, 244, 244);
    width: 100%;
  }

  .navbar-nav-footer .nav-link {
    color: grey;
  }

  .navbar-nav-footer .nav-link :nth-child(2) {
    font-size: small;
    font-weight: bold;
  }

  .navbar-nav-footer .nav-link.active {
    color: black;
  }

  .material-symbols-outlined {
    font-size: 34px;
  }
}

.error-message {
  color: red;
}

.rounded5 {
  border-radius: 20px;
  width: 500px;
  object-fit: cover;
  margin-top: 1rem;
  height: 450px;
}

.w40 {
  width: 47%;
}

h2[data-v-1e3b5c44] {
  font-weight: 600;
  font-size: 1rem;
  margin-top: 0.4rem !important;
  color: black;
}

.eight {
  /* width: 85% !important; */
}

.text-font {
  font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 500 !important;
  font-style: normal !important;
  font-size: 18px !important;
  line-height: 36px !important;
  color: rgb(93, 89, 108) !important;
}

.Basic-text-font {
  font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 500 !important;
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 36px !important;
  color: #7367f0 !important;
}

.text-size {
  font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 400 !important;
  font-style: normal !important;
  font-size: 15px !important;
  line-height: 22px !important;
  color: #a5a3ae !important;
}

.card {
  background-clip: padding-box;
  box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
}

.email-mask {
  margin-bottom: 0.25rem !important;
  font-size: 0.8125rem !important;
  color: #5d596c;
}

.btn.btn-primary {
  width: 100%;
  background-color: #7367f0;
  border: 2px solid #7367f0;
  margin-top: 1.5rem;
}

.link-muted {
  color: #7367f0;
}

/* .input-text {
  font-weight: 400 !important;
  font-style: normal !important;
  font-size: 15px !important;
  line-height: 22px !important;
  color: rgb(111, 107, 125) !important;
  font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
} */

.display-gap {
  justify-content: space-evenly;
}

/* .text-font {
  font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 400 !important;
  font-style: normal !important;
  font-size: 1rem !important;
  line-height: 22px !important;
  color: #6f6b7d !important;
} */

.custom-nav-link {
  background-color: white;
  color: #6f6b7d;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  transition: background-color 0.3s, color 0.3s;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.active-tab {
  background-color: #7367f0;
  color: white;
}

.custom-nav-link i {
  margin-right: 0.5rem;
}

.mr-li {
  margin: 0.125rem 0;
}

.bg-light-text {
  --bs-bg-opacity: 1;
  background-color: white !important;
  background-clip: padding-box;
  box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
}

.border-color {
  border-color: snow;
}
.tip-item {
  font-size: 14px;
  color: #495057;
  line-height: 1.6;
  margin-bottom: 15px;
}
/* Container Styles */
.progress-container {
  width: 100%;
  margin: 0;
  text-align: center;
  padding: 20px;
}

/* Step Titles */
.progress-steps {
  list-style: none;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0;
}

.progress-steps .step {
  font-size: 14px;
  font-weight: 500;
  color: #9e9e9e;
  position: relative;
}

.progress-steps .active {
  color: #7367f0; /* Highlight active step */
  font-weight: 600;
}

/* Step Indicators */
.progress-indicators {
  list-style: none;
  display: flex;
  justify-content: space-between;
  margin: 0 10px;
  padding: 0;
}

.progress-indicators .indicator {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #e0e0e0;
}

.progress-indicators .active {
  background-color: #7367f0; /* Green for active step */
}

/* Progress Bar */
.progress-bar-container {
  width: 100%;
  height: 8px;
  background-color: #e0e0e0;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

.progress-bar {
  width: 0%; /* Adjust width dynamically based on the current step */
  height: 100%;
  background-color: #7367f0;
  transition: width 0.3s ease-in-out;
}

</style>
